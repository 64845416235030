import React from "react";
import { Link } from 'react-router-dom'

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer" id="contact-us">
                <div className="container">
                    <div className="footer-list">
                        <div style={{ textAlign: 'center' }}>
                            <Link to="/">
                                <img alt="Slide" src="assets/img/logo.png" className="logo" style={{ maxWidth: '100%' }} />
                            </Link>
                        </div>
                        <div>
                            <h4>Policy &amp; Terms</h4>
                            <ul>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/terms">Terms of Use</Link></li>
                                <li><Link to="/cookie-policy">Cookie Policy</Link></li>
                                <li><Link to="/download/media">Download Media</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h4>Our Social</h4>
                            <ul>
                                <li>Line@&nbsp;:&nbsp;<a target="_blank" href="https://line.me/R/ti/p/%40956pfqbd">@skitz</a></li>
                                <li>Facebook&nbsp;:&nbsp;<a target="_blank" href="https://www.facebook.com/skitzapp">Skitz</a></li>
                            </ul>
                        </div>
                        <div>
                            <h4>Contact Us</h4>
                            <ul>
                                <li>Email&nbsp;:&nbsp;<a href="mailto:support@skitz.com">support@skitz.com</a></li>
                                <li>Telephone&nbsp;:&nbsp;<a href="tel:+6581221542">+6581221542</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}