import React, { useState } from 'react';
import { isAndroid, isIOS, isWindows, isMobile, isDesktop } from 'react-device-detect';
import { Helper } from '../Helper';
export default function ConnectBitKubCallback() {
    const [isLoading, setIsLoading] = useState(true);
    const [code, setCode] = useState(false)
    React.useEffect(() => {
        if (Helper.getParameterByName('code') && Helper.getParameterByName('code') != "") {
            setCode(Helper.getParameterByName('code'));
        } else {
            setCode("eiei");
        }

        setIsLoading(false);
        window.ReactNativeWebView.postMessage(Helper.getParameterByName('code'));
        window.ReactNativeWebView.postMessage("goBack");

    }, []);



    return (
        <>
            <div className="bg-1" >
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="login-content login-content-fluid" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={window.location.origin + "/assets/img/logo.png"} />
                            {
                                isLoading ?
                                    <>
                                        <div className="ui active inline loader inverted" ></div>
                                        <h6 className="mt-3 sub-heading text-center">กำลังโหลด</h6>
                                    </>
                                    : null
                            }
                            {
                                !isLoading ?
                                    <>
                                        <div className="title d-inline-block mb-2" >
                                            <h6 className="mb-3 sub-heading text-center">ใช้ SKITz ในสถานที่ของคุณฟรี</h6>
                                            <h3 className="mb-3 heading text-center">Bitkub oauth2</h3>
                                            <p className="text text-center">Bitkub oauth2 callback</p>
                                        </div>

                                        <div className={" justify-content-center align-items-center"} >
                                            <div style={{ color: 'white' }}>{code || '-'}</div>
                                        </div>
                                    </>
                                    : null
                            }
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}