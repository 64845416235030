import { Helper } from '../Helper';

const axios = require('axios').default;
//DEV_PROD
//const skitz_api_host = "https://api.skitz.com/api/v1";
// export const skitz_api_host = "https://skitz.beefastdev.com/api/v1";
let api_host = process.env.REACT_APP_SKITZ_API + '/api/v1';

let is_emulators = false;
if (window.location.origin.indexOf('localhost:') !== -1) {
    is_emulators = true;
}
if (is_emulators) {
    // api_host = "http://192.168.1.105:5100/api/v1";
}
export const skitz_api_host = api_host;

let currentUser = Helper.getCookie('currentUser') ? JSON.parse(Helper.getCookie("currentUser")) : false;
let uid = currentUser.uid;
console.log(uid)
if(!uid) {
    uid = window.currentUserUid;
} else {
    window.currentUserUid = currentUser.uid
}
console.log(uid)
const ApplicationKey = 'ae9f62205182411d82e2b3ce2bfae7fd';
const httpClient = axios.create();
httpClient.interceptors.request.use(async (config) => {
    config.headers = {
        // 'applicationkey': ApplicationKey,
        'firebaseuid': uid,
        'clientid': 'skitzweb'
    }
    return config;
},
    error => {
        Promise.reject(error)
    });
export default httpClient