import React from 'react';
const axios = require('axios').default;

export default class PlacePreview extends React.Component {
    constructor() {
        super();
        this.state = {
            
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let request_data = {
            store_id: this.props.store_id
        };
        let res = await axios.post(window.api_host + '/store', request_data);
        if (res.code === 200) {
            // DO
        }
        if (res.code !== 200) {
            // DO
        }
    }

    render() {
        if (!this.props.store_id) {
            return (<><h1>No Store ID</h1></>)
        }
        return (
            <>
                <h1>Place Preview</h1>
            </>
        )
    }
}