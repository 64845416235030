import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams
} from "react-router-dom";
import Header from "../Include/Header";
import Footer from "../Include/Footer";
import { isAndroid, isIOS, isWindows, isMobile, isDesktop } from 'react-device-detect';
const axios = require('axios').default;
const default_android_redirect = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.banpuen.user";
const default_ios_redirect = "https://apps.apple.com/us/app/skitz-chat-metaverse/id1531519059";
export default function Invite() {
    let { response_id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    React.useEffect(() => {
        axios.post(`https://${process.env.REACT_APP_CLOUND_FUNCTION_HOST}/appService/save-invite-reference`, { reference: response_id });
        linktoapp();

        setIsLoading(false)
    }, []);

    function linktoapp() {

        var app = {
            launchApp: function () {
                window.location.replace("skitzuser://skitz");
                this.timer = setTimeout(this.openWebApp, 1000);
            },

            openWebApp: function () {
                let red;
                if (isAndroid) {
                    red = default_android_redirect;
                } else if (isIOS) {
                    red = default_ios_redirect;
                }
                window.location.replace(red);
            }
        };

        app.launchApp();
    }


    return (
        <>
            {/* <Header /> */}
            <div className="container">
                {/* <h2 style={{ textAlign: 'center' }}>Download Application</h2> */}
                <div className={" justify-content-center align-items-center"} style={{ display: 'flex', flexDirection: 'column', minHeight: "80vh" }}>
                    <div className="d-flex justify-content-center align-items-center">
                        {
                            isAndroid || isDesktop ?
                                <a href={default_android_redirect} id="android_btn" className="mx-4" target={"_blank"}>
                                    <img src={window.location.origin + "/assets/images/design/googleplay.png"} className="image-googleplay" />
                                </a>
                                : null
                        }
                        {
                            isIOS || isDesktop ?
                                <a href={default_ios_redirect} id="ios_btn" className="mx-4">
                                    <img src={window.location.origin + "/assets/images/design/appstore.png"} className="image-appstore" />
                                </a>
                                : null
                        }
                    </div>

                    {!isLoading ? <div className="mt-4 d-flex justify-content-center align-items-center">
                        <button className={"btn login-btn"} id="run-app" style={{ width: 'auto' }} onClick={() => linktoapp()}>กดเพื่อกลับไปยัง application</button>
                    </div> : null}
                </div>
            </div>
            {/* <Footer /> */}
        </>
    );
}