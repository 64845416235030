import React from "react";
import TermsDetail from "../Components/TermsDetail";
export default class ApplicationTerms extends React.Component {
    componentDidMount() {
        window.$('#cookieWow').remove();
        window.$('body').css("background", "white")
        window.$('html').css("background", "white")
    }
    render() {
        return (
            <>
                <div className="container" style={{ padding: 0, width: '100%' }}>
                    <div className="card-box" style={{ padding: 15, fontSize: 14 }}>
                        <div style={{ width: '100%', height: 30 }}></div>
                        <TermsDetail />
                    </div>
                </div>
            </>
        )
    }
}