import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/payment`;
export const postPayment = async (data) => {
    const url = `${baseUrl}`;
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const getPaymentByTransactionNumber = async (transactionNumber) => {
    const url = `${baseUrl}/transaction/number/${transactionNumber}`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}