import React from 'react';
import { Helper } from '../Helper';
import { api_host, db } from './../firebase';
import Slider from "react-slick";
import { getLives, getLiveRequestList } from '../services/ArtistServices';
const settings = {
    slidesToScroll: 1,
    draggable: false,
    infinite: true,
    swipeToSlide: false,
    vertical: true,
    verticalSwiping: false,
    centerMode: false,
    pauseOnHover: false,
    autoplaySpeed: 2000,
}

const slideMoveAmount = 4;
export default class Live extends React.Component {
    constructor() {
        super();
        this.state = {
            brandList: [],
            playingBrand: false,
            musicList: [],
            isLoading: true,
            isMusicListLoading: true,
            isAuto: false,
            uid: Helper.getParameterByName('uid'),
            snapShotdata: false,
        }
    }

    componentDidMount() {
        this.fecth();
        window.$('body,html').css("background-image", "none");
    }
    fecth() {
        let storeUid = this.state.uid;
        db.collection(`onSnapArtistLive`).doc(storeUid).onSnapshot(async snapDoc => {
            // console.log(snapDoc.empty);
            if (!snapDoc.empty) {
                let data = snapDoc.data();
                let brandList = [];
                if (this.state.snapShotdata.artistInLobbyCount != data.artistInLobbyCount) {
                    let brand = await getLives({
                        storeFirebaseUid: storeUid,
                        statusIds: 1,
                    })
                    brandList = brand;
                }
                let live = await getLives({
                    storeFirebaseUid: storeUid,
                    statusIds: 2,
                })
                let playingBrand = false;
                if (live.length > 0) {
                    playingBrand = live[0]
                }
                let musicList = [];
                if (playingBrand) {
                    let music = await getLiveRequestList(playingBrand.id, {
                        // statusIds: '1,2',
                        sortBy: 'tip-desc'
                    });
                    if (!music.error) {
                        musicList = music.filter((e) => { return e.statusId == 1 || e.statusId == 2 });
                    }
                }
                this.setState({
                    snapShotdata: data,
                    brandList: brandList,
                    playingBrand: playingBrand,
                    isLoading: false,
                    musicList: musicList,
                    isMusicListLoading: false,
                })
            } else {
                this.setState({
                    isLoading: false,
                })
            }
        });
    }


    render() {
        return (
            <div className="container" style={{ maxWidth: 1250 }}>
                {
                    this.state.snapShotdata
                        && this.state.snapShotdata.liveMessage ?
                        <>
                            <div class="warning alert mt-4">
                                <div class="content">
                                    <div class="icon">
                                        {/* <svg height="50" viewBox="0 0 512 512" width="50" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#fff" d="M449.07,399.08,278.64,82.58c-12.08-22.44-44.26-22.44-56.35,0L51.87,399.08A32,32,0,0,0,80,446.25H420.89A32,32,0,0,0,449.07,399.08Zm-198.6-1.83a20,20,0,1,1,20-20A20,20,0,0,1,250.47,397.25ZM272.19,196.1l-5.74,122a16,16,0,0,1-32,0l-5.74-121.95v0a21.73,21.73,0,0,1,21.5-22.69h.21a21.74,21.74,0,0,1,21.73,22.7Z" />
                                        </svg> */}
                                        <div style={{ padding: 10 }}>
                                            <i className='fas fa-megaphone' style={{ fontSize: 33, color: '#fff' }} />
                                        </div>

                                    </div>
                                    <div>
                                        <h4 className={"mb-1 alert-heading"}>ประกาศจากทางร้าน</h4>
                                        <h1 className='mb-0'>{this.state.snapShotdata.liveMessage}</h1>
                                    </div>
                                </div>
                            </div>

                        </>
                        : null
                }
                {
                    !this.state.playingBrand ?
                        <h1 style={{ textAlign: 'center', padding: 15 }}>ไม่มีวงดนตรีเล่น</h1>
                        :
                        <>
                            {/* <div className={"brand-grid"}>
                                <div>
                                    {
                                        this.state.playingBrand ?
                                            <div className={"brand-up"}>
                                                <div>
                                                    {
                                                        this.state.playingBrand.artist && this.state.playingBrand.artist.photoURL ?
                                                            <img src={this.state.playingBrand.artist.photoURL} alt={"brand"} />
                                                            : <img src={'./assets/img/logo.png'} alt={"brand"} />
                                                    }
                                                </div>
                                                <div className={"brand-details"}>
                                                    <h3>{this.state.playingBrand.artist.displayName}</h3>
                                                    <p><i className="far fa-clock"></i> เริ่มเล่น {new Date(this.state.playingBrand.playingAt).getHours() + ":" + ("0" + new Date(this.state.playingBrand.playingAt).getMinutes()).substr(-2)} น.</p>
                                                </div>
                                            </div>


                                            : null
                                    }

                                </div> */}

                            <div style={{
                                maxWidth: '100%', margin: 'auto', paddingTop: this.state.snapShotdata
                                    && this.state.snapShotdata.liveMessage ? 0 : 80
                            }}>
                                {/* <h2 style={{ color: '#000' }}>รายการขอเพลง</h2> */}
                                <div style={{ padding: 15, background: '#f4f4f4', borderRadius: 10, height: 720 }}>
                                    <div style={{ height: '100%', overflow: 'hidden' }}>
                                        {/* {
                                                this.state.musicList > 5 ?
                                                    <SlideMusic is_loading={this.state.is_loading} musicList={this.state.musicList} />
                                                    : <SlideMusic is_loading={this.state.is_loading} musicList={this.state.musicList} />
                                            } */}
                                        <SlideMusic is_loading={this.state.is_loading} musicList={this.state.musicList} />
                                    </div>
                                </div>
                            </div>

                            {/* </div> */}
                        </>
                }
            </div>
        )
    }
}


class SlideMusic extends React.Component {
    render() {
        let count = 0;
        return (
            <Slider className={"song-list"}
                {...settings}
                slidesToShow={this.props.musicList.length > slideMoveAmount ? slideMoveAmount : this.props.musicList.length > 0 ? this.props.musicList.length : 1}
                autoplay={true}
            >
                {
                    !this.props.is_loading
                        && this.props.musicList.length > 0 ?
                        this.props.musicList.map((musicList, music_i) => {
                            count++;
                            return (
                                <div key={music_i} >
                                    <div className={"brand-card"} style={{ height: 165, marginBottom: 5 }} >
                                        <div className={"brand-card-no"}>
                                            {
                                                musicList.statusId == 2 ?
                                                    <i
                                                        className="fas fa-play animate__animated animate__heartBeat animate__infinite"
                                                        style={{
                                                            fontSize: 30,
                                                        }}
                                                    />
                                                    :
                                                    <i className="fas fa-pause" style={{ fontSize: 30 }} />
                                            }
                                        </div>
                                        <div className={"brand-card-content"}>
                                            <div className={"brand-card-icon"}>
                                                {
                                                    musicList.coverImage ?
                                                        <img src={musicList.coverImage} />
                                                        :
                                                        <i className="fas fa-music" style={{ fontSize: 50 }} />
                                                }
                                            </div>
                                            <div className={"brand-card-details"}>
                                                <div><strong>{musicList.musicName}</strong></div>
                                                <div><p>{musicList.artistName}</p></div>
                                                {/* <div>{musicList.userDisplayName}</div> */}
                                            </div>
                                            {
                                                musicList.tipAmount > 0 ?
                                                    <div className="right-floated">

                                                        <div className={"d-flex align-items-center justify-content-end"}>
                                                            Tip: {Helper.numberFormat(musicList.tipAmount)}
                                                            <img src={`${window.location.origin}/assets/img/skitzpoint.png`} style={{ width: 20, marginLeft: 5 }} />
                                                        </div>

                                                    </div>
                                                    : null
                                            }

                                            {/* {
                                                                                    musicList.isPlaying ?
                                                                                        <div style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'column',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center'
                                                                                        }}>
                                                                                            <i className="far fa-play-circle" style={{ fontSize: 30 }}></i>
                                                                                            à¸à¸³à¸¥à¸±à¸‡à¹€à¸¥à¹ˆà¸™
                                                                                        </div>
                                                                                        : null
                                                                                } */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : null
                }
                {
                    !this.props.is_loading && this.props.musicList.length === 0 ?
                        <div>
                            <div className={"brand-card"} style={{ height: 100 }}>
                                <div className={"brand-card-no"}>
                                    <i className="fas fa-times"></i>
                                </div>
                                <div className={"brand-card-content"}>
                                    <div className={"brand-card-icon"}>
                                        <i className="fas fa-music"></i>
                                    </div>
                                    <div className={"brand-card-details"} style={{ justifyContent: 'center' }}>
                                        <div><strong>ไม่มีรายการขอเพลง</strong></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </Slider>
        );
    }
}