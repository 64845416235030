import React from 'react';
export default class TermsDetail extends React.Component {
    render() {
        return (
            <>
                <h5 style={{ fontWeight: 'bold', textAlign: 'center' }}>นโยบายคุกกี้</h5>
                <p>นโยบายคุกกี้ฉบับนี้ได้อธิบายความหมายและวิธีการใช้คุกกี้ของเว็บไซต์ skitz.com โปรดศึกษานโยบายคุกกี้ฉบับนี้เพื่อให้คุณสามารถเข้าใจแนวปฏิบัติของเราเกี่ยวกับการเก็บรวบรวม ใช้ หรือการเปิดเผยคุกกี้ รวมถึงทางเลือกในการใช้คุกกี้ของเรา</p>

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >คุกกี้คืออะไร ?</h5>
                <p>คุกกี้ คือ ไฟล์ข้อมูลขนาดเล็กที่ฝังอยู่ในคอมพิวเตอร์หรืออุปกรณ์ของคุณเมื่อคุณเยี่ยมชมเว็บไซต์ หลังจากนั้นคุกกี้จะถูกส่งกลับไปยังเว็บไซต์ต้นทางในแต่ละครั้งของการเข้าเยี่ยมชมในครั้งถัดไปหรือส่งไปยังเว็บไซต์อื่นที่จดจำคุกกี้นั้นได้ คุกกี้เหล่านี้ถูกใช้เพื่อทำให้เว็บไซต์สามารถทำงานได้หรือทำงานได้อย่างมีประสิทธิภาพ รวมถึงการให้ข้อมูลแก่เจ้าของเว็บไซต์</p>
                <p>คุกกี้แบบชั่วคราว (Session Cookies) คือ คุกกี้ที่มีระยะเวลาชั่วคราวจะถูกใช้ในระหว่างที่คุณเยี่ยมชมเว็บไซต์และจะสิ้นสุดลงเมื่อคุณปิดบราวเซอร์</p>
                <p>คุกกี้แบบถาวร (Persistent Cookies) คือ คุกกี้ที่มีระยะเวลาอยู่ตลอดจนกว่าหมดอายุหรือถูกลบ ใช้จดจำการตั้งค่าของคุณภายในเว็บไซต์ และจะยังคงอยู่ในคอมพิวเตอร์หรือบนมือถือของคุณแม้ว่าคุณจะปิดเว็บไซต์นั้นแล้ว คุกกี้ดังกล่าวนี้จะช่วยเรื่องความสอดคล้องของข้อมูลและช่วยเพิ่มประสิทธิภาพการใช้งานในขณะที่คุณเยี่ยมชมเว็บไซต์หรือบริการ</p>
                <p>คุกกี้ของบุคคลที่หนึ่ง คือ คุกกี้ที่ถูกกำหนดโดยเว็บไซต์ที่คุณกำลังเยี่ยมชม</p>
                <p>คุกกี้ของบุคคลที่สาม คือ คุกกี้ถูกกำหนดโดยเว็บไซต์ของบุคคลที่สามแยกต่างหากจากไซต์ที่คุณกำลังเยี่ยมชม เช่น ผู้ให้บริการข้อมูล โฆษณา หรือการวิเคราะห์บนเว็บไซต์</p>
                <p>เทคโนโลยีที่คล้ายคลึงกัน  คือ เทคโนโลยีที่จัดเก็บข้อมูลในบราวเซอร์หรืออุปกรณ์ของคุณเพื่อแบ่งปันข้อมูลหรือจัดเก็บข้อมูล เช่น แฟลชคุกกี้ HTML 5 และวิธีการอื่น ๆ ของซอฟต์แวร์แอปพลิเคชันบนเว็บไซต์</p>

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >เราใช้คุกกี้อย่างไร ?</h5>
                <p>เราจะจัดเก็บข้อมูลการเข้าเยี่ยมชมเว็บไซต์ของเราผ่านคุกกี้หรือเทคโนโลยีที่คล้ายคลึงกัน โดยเราจะใช้เพื่อวัตถุประสงค์ดังต่อไปนี้:</p>

                <p>เพื่อให้การเข้าบัญชีผู้ใช้งานบนเว็บไซต์ของเราเป็นไปอย่างต่อเนื่องและปลอดภัย</p>
                <p>เพื่อบันทึกข้อมูลการใช้งานและการตั้งค่าของคุณบนเว็บไซต์ของเรา</p>
                <p>เพื่อวิเคราะห์พฤติกรรมการใช้งานของคุณบนเว็บไซต์ของเรา</p>
                <p>เพื่อพัฒนาประสิทธิภาพในการใช้งานและการเข้าถึงบริการของเรา</p>
                <p>เพื่อเก็บรวบรวมความสนใจ และเพิ่มความสามารถในการตอบสนองความต้องการในการใช้งานของคุณมากขึ้น</p>

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >ประเภทคุกกี้ที่เราใช้</h5>
                <p>ประเภทคุกกี้ที่เราใช้บนเว็บไซต์ มีดังต่อไปนี้</p>

                <p><b>คุกกี้ที่มีความจำเป็นอย่างยิ่ง (Strictly Necessary Cookies)</b></p>
                <p>คุกกี้เหล่านี้มีความสำคัญต่อการให้บริการบนเว็บไซต์แก่คุณและเพื่อให้คุณสามารถใช้คุณลักษณะบางอย่างได้ คุกกี้เหล่านี้ช่วยในการยืนยันตัวบุคคลของผู้ใช้งานและช่วยป้องกันการปลอมแปลงบัญชีผู้ใช้งาน หากไม่มีคุกกี้เหล่านี้เราอาจไม่สามารถให้บริการแก่คุณได้ เราใช้คุกกี้ดังกล่าวนี้เพื่อให้บริการแก่คุณ</p>

                <p><b>คุกกี้เพื่อการทำงานของเว็บไซต์ (Functional Cookies)</b></p>
                <p>คุกกี้เหล่านี้ช่วยให้เราจดจำสิ่งที่คุณเลือกเมื่อคุณใช้งานเว็บไซต์ เช่น การจดจำรายละเอียดการเข้าสู่ระบบหรือการตั้งค่าภาษาของคุณ วัตถุประสงค์ของคุกกี้เหล่านี้มีเพื่อให้คุณได้รับประสบการณ์ที่เป็นส่วนตัวมากขึ้นและเพื่อหลีกเลี่ยงไม่ให้คุณต้องใส่ข้อมูลของคุณใหม่ทุกครั้งเมื่อคุณใช้งานเว็บไซต์ คุกกี้ดังกล่าวนี้เป็นคุกกี้แบบถาวรเนื่องจากยังคงอยู่ในอุปกรณ์ของคุณเพื่อให้เราใช้ในการเยี่ยมชมเว็บไซต์ครั้งต่อไปของคุณ คุณสามารถลบคุกกี้เหล่านี้ผ่านบราวเซอร์ของคุณได้</p>

                <p><b>คุกกี้เพื่อการวิเคราะห์/เพื่อประสิทธิภาพ (Analytical/Performance Cookies)</b></p>
                <p>คุกกี้เหล่านี้ช่วยให้เราจดจำและนับจำนวนผู้ใช้งานบนไซต์ของเรา รวมถึงทำให้เราเข้าใจถึงวิธีการที่ผู้ใช้งานใช้งานบนเว็บไซต์ของเรา สิ่งเหล่านี้จะช่วยปรับปรุงวิธีการทำงานของเว็บไซต์เรา เช่น เพื่อให้มั่นใจว่าผู้ใช้งานสามารถค้นหาสิ่งที่ต้องการได้ง่ายขึ้น</p>

                <p><b>คุกกี้เพื่อการโฆษณา (Advertising Cookies)</b></p>
                <p>คุกกี้เหล่านี้ใช้เพื่อแสดงโฆษณาที่เกี่ยวข้องกับคุณ คุกกี้เหล่านี้จะจำกัดจำนวนครั้งที่คุณเห็นโฆษณาและช่วยให้เราประเมินประสิทธิภาพด้านการตลาด นอกจากนี้เราอาจใช้ข้อมูลที่ได้รับจากคุกกี้เหล่านี้เพื่อเสนอโฆษณาที่คุณอาจสนใจโดยขึ้นอยู่กับพฤติกรรมการใช้งานออนไลน์ของคุณที่ผ่านมา คุกกี้ดังกล่าวนี้เป็นคุกกี้แบบถาวร เราอาจแบ่งปันข้อมูลนี้กับบุคคลที่สามรวมถึงพันธมิตรทางธุรกิจของเรา</p>

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >ทางเลือกเกี่ยวกับคุกกี้</h5>
                <p>ในกรณีที่คุณไม่ประสงค์ให้มีการใช้งานคุกกี้บนเว็บไซต์ของเรา คุณสามารถเปลี่ยนแปลงได้ด้วยการตั้งค่าบนบราวเซอร์ของคุณเพื่อเลือกการใช้งานที่เหมาะสมกับคุณ หากคุณปิดการใช้งานหรือลบคุกกี้บางอย่างในการตั้งค่าบราวเซอร์ของคุณ ทั้งนี้ การใช้งานบนเว็บไซต์หรือคุณลักษณะบางอย่างอาจทำงานได้ไม่ตรงตามวัตถุประสงค์</p>
                <p>หากคุณต้องการศึกษาเพิ่มเติมเกี่ยวกับการใช้คุกกี้และการตั้งค่าคุกกี้ดังกล่าว คุณสามารถเข้าไปที่หน้าเว็บไซต์ทางการบราวเซอร์ของคุณได้ คุณสามารถดูรายละเอียดเกี่ยวกับวิธีการปฏิเสธหรือการลบคุกกี้ ตลอดจนข้อมูลเพิ่มเติมทั่วไปเกี่ยวกับคุกกี้ได้ที่ <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a></p>

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >การแก้ไขเปลี่ยนแปลงนโยบายคุกกี้</h5>
                <p>เราอาจแก้ไขเปลี่ยนแปลงนโยบายคุกกี้ฉบับนี้เป็นครั้งคราว โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา</p>
                <p>นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 31 พฤษภาคม 2565</p>

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >รายละเอียดการติดต่อ</h5>
                <p>หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายคุกกี้ฉบับนี้ คุณสามารถติดต่อเราได้ ดังนี้</p>

                <p><b>ผู้ควบคุมข้อมูลส่วนบุคคล</b></p>
                <p>บริษัท ยูเอ็กซ์ดี (ไทยแลนด์) จำกัด</p>
                <p>
                    50/804 หมู่ที่ 9 บางพูด ปากเกร็ด นนทบุรี 11120
                </p>
                <p>อีเมล support@skitz.com</p>
                <p>เว็บไซต์ https://skitz.com</p>

                <p><b>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</b></p>
                <p>ชื่อ อัฐกร อิทธิเตชสี</p>
                <p>
                    สถานที่ติดต่อ 50/804 หมู่ที่ 9 บางพูด ปากเกร็ด นนทบุรี 11120
                </p>
                <p>อีเมล attakorn@skitz.com</p>
            </>
        )
    }
}