import React from 'react';
import { Helper } from '../../Helper';
import InputMask from 'react-input-mask';
import { auth, firebaseAuth, mainFirebase } from '../../firebase';
import { Redirect } from "react-router-dom";
// const axios = require('axios').default;
export default class UserLogin extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            redirect: false,
        }
    }

    componentDidMount() {
        let currentUser = Helper.getCookie('currentUser') ? JSON.parse(Helper.getCookie("currentUser")) : false;
        if (currentUser) {
            this.setState({
                redirect: Helper.loginCheckRedirect()
            })
        } else {
            this.setState({
                is_loading: false,
            }, () => {
                this.setupRecaptcha();
            })
        }

    }

    setupRecaptcha() {
        window.phoneRecaptchaVerifier = new firebaseAuth.RecaptchaVerifier('phone-sign-in-recaptcha', {
            'size': 'invisible',
            'callback': function (response) {
                // reCAPTCHA solved - will proceed with submit function
            },
            'expired-callback': function () {
                // Reset reCAPTCHA?
                window.location = window.location;
            }
        }, mainFirebase);
    }


    onSubmit(data) {
        var phoneNoSpace = data.phoneNumber.replace(/\s/g, '');
        if (phoneNoSpace.length == 13) {
            var str = phoneNoSpace;
            phoneNoSpace = str.slice(0, 3) + str.slice(4);
        } else if (phoneNoSpace.length == 14) {
            phoneNoSpace = str.slice(0, 3) + str.slice(5);
        }
        let phoneNumber = '+66' + phoneNoSpace.substr(1, 9);
        auth.signInWithPhoneNumber(phoneNumber, window.phoneRecaptchaVerifier).then((confirmResult) => {
            // console.log(confirmResult)
            let url_path = `/user/otp?verificationId=${confirmResult.verificationId}&phoneNumber=${phoneNumber}`;
            let type = Helper.getParameterByName("type") || false;
            if (type && type == "artist") {
                url_path += `&type=artist`;
            }
            let storeUid = Helper.getParameterByName("storeUid") || false;
            if (storeUid) {
                url_path += `&storeUid=${storeUid}`;
            }
            this.setState({
                redirect: url_path,
            })
        }).catch((error) => {
            if (error.code === "auth/quota-exceeded") {
                Helper.messageTop({
                    message: 'ไม่สามารถส่ง SMS OTP ไม่เนื่องจากมีการขอ SMS มากเกินไป',
                })
            } else if (error.code === "auth/user-disabled") {
                Helper.messageTop({
                    message: 'เบอร์ของคุณถูกยกเลิก',
                })
            } else if (error.code === "auth/invalid-phone-number") {
                Helper.messageTop({
                    message: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
                })
            } else if (error.code === "auth/captcha-check-failed") {
                Helper.messageTop({
                    message: 'ยืนยันตัวตนไม่สำเร็จ',
                })
            } else {
                Helper.messageTop({
                    message: 'กรุณาลองใหม่อีกครั้ง',
                })
            }
            this.submit.removeClass('loading');
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }
        return (
            <div className="bg-1" >
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="login-content login-content-fluid" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <a href="/"><img src={window.location.origin + "/assets/img/logo.png"} /></a>
                            {
                                this.state.is_loading ?
                                    <>
                                        <div className="ui active inline loader inverted" ></div>
                                        <h6 className="mt-3 sub-heading text-center">กำลังโหลด</h6>
                                    </>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className="title d-inline-block mb-2" >
                                            <h6 className="mb-3 sub-heading text-center">ยินดีต้อนรับสู่เว็บไซด์ SKITz</h6>
                                            <h3 className="mb-1 heading text-center">เข้าสู่ระบบ</h3>
                                            {/* <p className="text text-center">กรุณากรอกเบอร์โทรศัพท์</p> */}
                                        </div>
                                        <form
                                            className="login-content mt-0"
                                            style={{
                                                width: 400
                                            }}
                                            ref={(ref) => this.$form = window.$(ref)}
                                            // style={{ padding: 15, height: 705, overflowY: 'auto' }}
                                            onSubmit={async (e) => {
                                                e.preventDefault();
                                                if (this.submit.hasClass('loading')) { return false; }
                                                this.submit.addClass('loading');
                                                let data = Helper.getFormInputObject(this.$form);
                                                data.phoneNumber = data.phoneNumber.replace(' ', '');
                                                data.phoneNumber = data.phoneNumber.replace('-', '');
                                                this.onSubmit(data);

                                            }}
                                        >
                                            <div className="form-group">
                                                <label>กรุณากรอกเบอร์โทรศัพท์ <small style={{ color: 'red' }}>*</small></label>
                                                <InputMask
                                                    style={{ letterSpcing: '2px' }}
                                                    mask="9 9 9 - 9 9 9 9 9 9 9"
                                                    className="form-control text-center"
                                                    name="phoneNumber"
                                                    required={true}
                                                />
                                                <p className="text-white mb-0">เราจะส่งข้อความพร้อมรหัสยืนยัน</p>
                                                <p className="text">เพื่อนำรหัสนั้นมายืนยันหมายเลขโทรศัพท์ของคุณ</p>
                                            </div>
                                            <div id="phone-sign-in-recaptcha"></div>
                                            <div className="d-flex justify-content-center">
                                                <button
                                                    ref={(ref) => {
                                                        this.submit = window.$(ref);
                                                    }}
                                                    type="submit"
                                                    className="btn btn-lg rounded-pill main-btn inverted">
                                                    ดำเนินการต่อ <i className='fas fa-arrow-right'></i>
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
