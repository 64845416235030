import React from "react";

export default class Social extends React.Component {
    render() {
        return (
            <div className="social-fixed">
                <ul>
                    <li>
                        <a className="social-box" href="">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a className="social-box" href="">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li >
                        <a className="social-box" href="">
                            <i className="fab fa-twitter"></i>
                        </a>
                    </li>
                </ul>
            </div>
        )
    }
}