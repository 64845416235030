import React from 'react';
import { Helper } from '../../Helper';
import InputMask from 'react-input-mask';
import { auth, firebaseAuth, mainFirebase } from '../../firebase';
import { Redirect } from "react-router-dom";
// const axios = require('axios').default;
export default class UserLogin extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            redirect: false,
        }
    }

    componentDidMount() {
        this.setState({
            is_loading: false,
        })
    }

    setupRecaptcha() {
        window.phoneRecaptchaVerifier = new firebaseAuth.RecaptchaVerifier('phone-sign-in-recaptcha', {
            'size': 'invisible',
            'callback': function (response) {
                // reCAPTCHA solved - will proceed with submit function
            },
            'expired-callback': function () {
                // Reset reCAPTCHA?
                window.location = window.location;
            }
        }, mainFirebase);
    }


    onSubmit(data) {
        console.log(data)
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }
        return (
            <div className="bg-1" >
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="login-content login-content-fluid" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <a href="/"><img src={window.location.origin + "/assets/img/logo.png"} /></a>
                            {
                                this.state.is_loading ?
                                    <>
                                        <div className="ui active inline loader inverted" ></div>
                                        <h6 className="mt-3 sub-heading text-center">กำลังโหลด</h6>
                                    </>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className="title d-inline-block mb-2" >
                                            <h6 className="mb-3 sub-heading text-center">ยินดีต้อนรับสู่เว็บไซด์ SKITz</h6>
                                            <h3 className="mb-1 heading text-center">ลงทะเบียนนักดนตรี</h3>
                                            {/* <p className="text text-center">กรุณากรอกเบอร์โทรศัพท์</p> */}
                                        </div>
                                        <form
                                            className="login-content mt-0"
                                            style={{
                                                width: 400
                                            }}
                                            ref={(ref) => this.$form = window.$(ref)}
                                            // style={{ padding: 15, height: 705, overflowY: 'auto' }}
                                            onSubmit={async (e) => {
                                                e.preventDefault();
                                                if (this.submit.hasClass('loading')) { return false; }
                                                this.submit.addClass('loading');
                                                let data = Helper.getFormInputObject(this.$form);
                                                this.onSubmit(data);
                                            }}
                                        >
                                            <div className="form-group">
                                                <label>ชื่อ <small style={{ color: 'red' }}>*</small></label>
                                                <input type="text" className="form-control" name="name" required={true} />
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <button
                                                    ref={(ref) => {
                                                        this.submit = window.$(ref);
                                                    }}
                                                    type="submit"
                                                    className="btn btn-lg rounded-pill main-btn inverted">
                                                    ลงทะเบียน
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
