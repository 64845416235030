import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import './index.css';
import { api_host, db } from './firebase';
import Slider from "react-slick";
import { Helper } from './Helper';
import RegisterPlace from './Page/RegisterPlace';
import RegisterStore from './Page/RegisterStore';
import RegisterSuccess from './Page/RegisterSuccess';
import Live from './Page/Live';
import Index from './Page/Index';
import Terms from './Page/Terms';
import Policy from './Page/Policy';
import PlacePreview from './Page/PlacePreview';
import DownloadMedia from './Page/DownloadMedia';
import DownloadApp from './Page/DownloadApp';
import CoinPackage from './Page/Coin/CoinPackage';
import CoinResult from './Page/Coin/CoinResult';
import UserLogin from './Page/User/UserLogin';
import UserOtp from './Page/User/UserOtp';
import Contact from './Page/Contact';
import Invite from './Page/Invite'
import ApplicationPolicy from './Page/ApplicationPrivacy';
import ApplicationTerms from './Page/ApplicationTerms'
import ConnectBitKubCallback from './Page/ConnectBitKubCallback'
import CookiePolicy from "./Page/CookiePolicy";
import ArtistForm from "./Page/Artist/Form"
import Lobby from "./Page/Artist/Lobby"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isAndroid, isIOS, isWindows } from 'react-device-detect';
const axios = require('axios').default;
let is_emulators = false;
if (window.location.origin.indexOf('localhost:') !== -1) {
  is_emulators = true;
}
window.api_host = api_host;
if (is_emulators) {
  window.api_host = "http://localhost:5001/skitz-app-test/asia-southeast2/backend";
}
export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/lobby" component={Lobby} />
        <Route path="/artist-create">
          <ArtistForm />
        </Route>
        <Route path="/application-privacy-policy">
          <ApplicationPolicy />
        </Route>
        <Route path="/application-terms">
          <ApplicationTerms />
        </Route>
        <Route path="/cookie-policy">
          <CookiePolicy />
        </Route>
        <Route path="/privacy-policy">
          <Policy />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/contact-us">
          <Contact />
        </Route>
        <Route path="/download-media">
          <DownloadMedia />
        </Route>
        <Route path="/download">
          <DownloadApp />
        </Route>
        <Route path="/post/:post_id">
          <Post />
        </Route>
        <Route path="/place-preview/:store_id">
          <PlacePreview />
        </Route>
        <Route path="/promotion/:promotion_id/:store_uid">
          <Promotion />
        </Route>
        <Route path="/recomment-place">
          <RegisterPlace mode="create" type={"place"} />
        </Route>
        <Route path="/register-store">
          <RegisterStore mode="create" type={"store"} />
        </Route>
        <Route path="/package/payment">
          <CoinResult />
        </Route>
        <Route path="/package">
          <CoinPackage />
        </Route>
        <Route path="/user/login">
          <UserLogin />
        </Route>
        <Route path="/user/otp">
          <UserOtp />
        </Route>
        <Route path="/register-store-success">
          <RegisterSuccess />
        </Route>
        <Route path="/live">
          <Live />
        </Route>
        <Route path="/downloading">
          <Download />
        </Route>
        <Route path="/invite/:response_id">
          <Invite />
        </Route>
        <Route path="/bitkub/oauth2/callback">
          <ConnectBitKubCallback />
        </Route>
        <Route path="/">
          <Index />
        </Route>
      </Switch>
    </Router>
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

function Post() {
  let { post_id } = useParams();
  const [post, setPost] = React.useState(false)
  const [comments, setComments] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [loadingComments, setLoadingComments] = React.useState(false)
  React.useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    // console.log(post_id);
    let data = {
      postId: post_id
    }
    let res = await axios.get(window.api_host + '/get-post?' + window.$.param(data));
    if (res.data.success && res.data.response) {
      let _post = res.data.response;
      setPost(_post);
    } else {
      setPost(false)
    }
    setLoading(false)
  }

  async function fetchComments() {
    let data = {
      postId: post_id,
      page: 1,
      limit: 100,
    }
    let res = await axios.get(window.api_host + '/get-comments?' + window.$.param(data));
    if (res.data.success && res.data.res) {
      let comments = res.data.res;
      setComments(comments);
      setLoadingComments(false);
    }

  }



  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 15 }}>
        <div className="ui active inline loader" ></div>
      </div>
    )
  }
  if (!post) {
    return <div className="d-flex justify-content-center align-items-center pt-5 pb-5"><h4 style={{ color: '#fff' }}>ไม่พบข้อมูลโพส</h4></div>;
  }
  return (
    <div className="card">
      {
        post
          && post.images.length > 0 ?
          <div style={{ marginBootom: 15 }}>
            <Slider {...settings} >
              {
                post.images.map((image, image_i) => {
                  return (
                    <div key={image_i} >
                      <img src={image.imageUrl} style={{ maxWidth: '100%', margin: 'auto' }} alt={"POSTIMAGES"} />
                    </div>
                  )
                })
              }
            </Slider>
          </div>
          : null
      }

      <div className="flex row" style={{ marginTop: 5 }}>
        <div className={post.like === 0 ? "right-floated text-descriiption" : "right-floated text-descriiption"}>
          <i className={"far fa-calendar-alt"} style={{ marginRight: 3 }} />  {Helper.getDateThai(post.created, { is_show_time: true })}
        </div>
      </div>
      <div className="description-pre-line">
        {post.message}
      </div>
      <div className="flex row" style={{ marginTop: 5 }}>
        <div className={"text-descriiption"}
          onClick={async () => {
            if (comments.length > 0 || post.commentCount == 0) { return; }
            setLoadingComments(true)
            fetchComments();
          }}
        >
          <i className={"far fa-comment-dots"} style={{ marginRight: 3 }} /> {post.commentCount} comments
        </div>
        <div className={post.emotionCount === 0 ? "right-floated text-descriiption" : "right-floated text-descriiption"}>
          <i className={"far fa-heart"} style={{ marginRight: 3 }} /> {post.emotionCount} likes
        </div>
      </div>
      {
        loadingComments ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 15 }}>
            <div className="ui active inline loader" ></div>
          </div>
          : null
      }
      {
        !loadingComments && comments.length > 0 ?
          comments.map((comment, comment_i) => {
            return (
              <React.Fragment key={comment_i}>
                <div className="divider" style={{ margin: '10px 0' }} />
                <div className="flex row flex-middle">
                  <div className="list-icon" style={{ marginRight: 25 }}>
                    <img src={comment.userImage} className="user-image" />
                  </div>
                  <div style={{ flex: 1 }}>
                    <div><strong>{comment.displayName || 'ไม่มีข้อมูล'}</strong></div>
                    <div><small>{comment.message}</small></div>
                  </div>
                  <div className="d-flex justify-content-end align-items-end right-floated text-descriiption">
                    {Helper.getDateThai(comment.created, { is_show_time: true })}
                  </div>
                </div>
              </React.Fragment>
            )
          })
          : null
      }

    </div>
  );
}
// /store-promotion
function Promotion() {

  let { promotion_id, store_uid } = useParams();
  const [promotion, setPromotion] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    // console.log(post_id);
    let data = {
      eventPromotionUid: promotion_id,
      uid: store_uid,
    }
    let res = await axios.get(window.api_host + '/store-promotion?' + window.$.param(data));
    if (res.data.success) {
      setPromotion(res.data.response.promotion)
      setLoading(false)
    }
  }
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 15 }}>
        <div className="ui active inline loader" ></div>
      </div>
    )
  }
  if (!promotion) {
    return null;
  }
  return (
    <div className="card">
      {
        promotion
          && promotion.photoPath.length > 0 ?
          <div style={{ marginBootom: 15 }}>
            <Slider {...settings} >
              {
                promotion.photoPath.map((image, image_i) => {
                  return (
                    <div key={image_i} >
                      <img src={image} style={{ maxWidth: '100%', margin: 'auto' }} alt={"PROMOTIONIMAGES"} />
                    </div>
                  )
                })
              }
            </Slider>
          </div>
          : null
      }
      <div className="flex row" style={{ marginTop: 5 }}>
        <div className={"right-floated text-descriiption"}>
          <i className={"far fa-calendar-alt"} style={{ marginRight: 3 }} />  {Helper.getDateThai(promotion.createdAt, { is_show_time: true })}
        </div>
      </div>
      <div className="description-pre-line">
        <strong>{promotion.nameTH}</strong><br />
        <small>{promotion.descriptionTH}</small>
      </div>
    </div>
  );
}

class Download extends React.Component {
  componentDidMount() {

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = "var domIsReady=function(e){e&&\"function\"==typeof e?\"ie\"!=(document.attachEvent&&void 0!==document.attachEvent?\"ie\":\"not-ie\")?document.addEventListener(\"DOMContentLoaded\",function(){return e()}):document.attachEvent(\"onreadystatechange\",function(){if(\"complete\"===document.readyState)return e()}):console.error(\"The callback is not a function!\")};!function(e,t,n,o){n(function(){setTimeout(function(){navigator.userAgent.toLowerCase().indexOf(\"android\")>-1?t.location.href=\"https://play.google.com/store/apps/details?id=com.banpuen.user\":navigator.userAgent.toLowerCase().indexOf(\"iphone\")>-1&&(t.location.href=\"https://apps.apple.com/us/app/skitz-chat-metaverse/id1531519059\")},500)})}(document,window,domIsReady);";
    //   console.log(this);
    document.body.appendChild(s);
    if (!isAndroid && !isWindows && !isIOS) {
      window.location.href = "/download-application";
    }
  }
  render() {

    return (
      <>
        <h1 style={{ color: '#fff', textAlign: 'center', paddingTop: 50 }}>
          กรุณารอสักครู่
          <div id="dot-bounce">
            <div id="bounce1" className="bounce text-mute"></div>
            <div id="bounce2" className="bounce text-mute"></div>
            <div id="bounce3" className="bounce text-mute"></div>
          </div>
        </h1>
      </>
    )
  }
}