import React from "react";
import { Link } from 'react-router-dom'

export default class Header extends React.Component {
    render() {
        return (
            <div className="page-header">
                <div className="container">
                    <div className="d-flex flex-wrap" style={{ justifyContent: 'center' }}>
                        <Link to="/">
                            <img src={window.location.origin + "/assets/img/logo.png"} style={{ height: 120 }} />
                        </Link>
                        <div className='right-floated d-flex align-items-center top-menu'>
                            <ul className="pb-0 pt-0 mb-0 mt-0 flex-wrap justify-content-center">
                                <li className="mx-3">
                                    <Link to="/">หน้าหลัก</Link>
                                </li>
                                {/* <li className="mx-3">
                                    <Link to="/about-us">เกี่ยวกับเรา</Link>
                                </li> */}
                                {/* <li className="mx-3">
                                    <Link to="/package">Package</Link>
                                </li> */}
                                {/* <li className="mx-3">
                                    <Link to="/whitelist">Whitelist</Link>
                                </li> */}
                                <li className="mx-3">
                                    <a onClick={() => {
                                        window.scrollTo(0, document.querySelector("#root").scrollHeight);
                                    }} href="#contact-us">ติดต่อเรา</a>
                                </li>
                                <li className="mx-3">
                                    <a href={`${process.env.REACT_APP_SKITZ_WEB_MUSIC}/artist-register`}>ลงทะเบียนนักดนตรี</a>
                                </li>
                                <li className="mx-3">
                                    <Link to="/recomment-place">แนะนำสถานที่</Link>
                                </li>
                                <li className="mx-3">
                                    <Link to="/register-store">สมัครร้านค้า</Link>
                                </li>
                                <li>
                                    <a href={`https://${process.env.REACT_APP_STORE_URL}/`} className="btn login-btn">เข้าสู่ระบบ</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}