import React from 'react';
import { Helper } from '../../Helper';
import InputMask from 'react-input-mask';
import { firebaseAuth, auth, mainFirebase } from '../../firebase';
import { Redirect } from "react-router-dom";
import { getArtist } from '../../services/ArtistServices';

export default class UserLogin extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            phoneNumber: false,
            verificationId: false,
            redirect: false,
        }
    }
    componentDidMount() {

        let currentUser = Helper.getCookie('currentUser') ? JSON.parse(Helper.getCookie("currentUser")) : false;
        let type = Helper.getParameterByName("type") || false;
        if (currentUser) {
            this.setState({
                redirect: Helper.loginCheckRedirect()
            })
        } else {
            let verificationId = Helper.getParameterByName('verificationId');
            let phoneNumber = Helper.getParameterByName('phoneNumber');
            this.setState({
                type: type,
                phoneNumber: phoneNumber,
                verificationId: verificationId,
                is_loading: false,
            }, () => {
                this.setupRecaptcha();
            })
        }
    }

    async onSubmit(code) {
        try {
            let otpCode = code.split(' ').join('');
            var credential = firebaseAuth.PhoneAuthProvider.credential(this.state.verificationId, otpCode);
            let signIn = await auth.signInWithCredential(credential);
            let _getUser = {
                displayName: auth.currentUser.displayName,
                uid: auth.currentUser.uid,
                phoneNumber: auth.currentUser.phoneNumber,
                email: auth.currentUser.email,
            }
            console.log(_getUser)
            window.currentUserUid = _getUser.uid;
            Helper.setCookie('currentUser', JSON.stringify(_getUser));
            let redirect = false;
            if (this.state.type && this.state.type == 'artist') {
                let storeUid = Helper.getParameterByName("storeUid") || false;
                let res = await getArtist(_getUser.uid);
                Helper.setCookie('currentArtist', JSON.stringify({ ...res, currentStoreUid: storeUid }));
                if (res.error) {
                    redirect = `/artist-create`;
                } else {
                    redirect = `/lobby`;
                }
            } else {
                window.location.href="/package"
            }
            this.setState({
                redirect: redirect,
            })

        } catch (error) {
            if (error.code === "auth/code-expired") {
                Helper.messageTop({ message: `OTP หมดอายุแล้ว` });
            }
            else if (error.code === "auth/invalid-verification-code") {
                Helper.messageTop({ message: `OTP ไม่ถูกต้อง` });
            }
            else if (error.code === "auth/user-disabled") {
                Helper.messageTop({ message: 'เบอร์ของคุณถูกยกเลิก', })
            }
            else if (error.code === "auth/user-not-found") {
                Helper.messageTop({ message: 'ไม่พบผู้ใช้งาน' });
                this.setState({
                    redirect: '/user/login'
                })
            }
            else {
                Helper.messageTop({ message: `เกิดข้อผิดพลาดกรุณาลองใหม่อีกรั้ง` });
            }
            this.submit.removeClass('loading');
        }
    }
    setupRecaptcha() {
        window.phoneRecaptchaVerifier = new firebaseAuth.RecaptchaVerifier('phone-sign-in-recaptcha', {
            'size': 'invisible',
            'callback': function (response) {
                // reCAPTCHA solved - will proceed with submit function
            },
            'expired-callback': function () {
                // Reset reCAPTCHA?
                window.location = window.location;
            }
        }, mainFirebase);
    }

    callOtp() {
        if (this.sendOtp.hasClass('loading')) { return; }
        this.sendOtp.addClass('loading')
        this.sendOtp.html('กำลังส่ง OTP');

        let preparePhone = this.state.phoneNumber.replace(' ', '');
        let phoneNumber = `+${preparePhone}`;
        auth.signInWithPhoneNumber(phoneNumber, window.phoneRecaptchaVerifier).then((confirmResult) => {
            this.setState({
                verificationId: confirmResult.verificationId,
            })
        });
        let countDown = 10;
        let time = setInterval(() => {
            this.sendOtp.html("กดส่งใหม่ได้ใน " + countDown)
            countDown--;
            if (countDown < 0) {
                this.sendOtp.removeClass('loading')
                this.sendOtp.html('ส่งใหม่');
                clearInterval(time)
            }
        }, 1000)
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }
        return (
            <div className="bg-1" >
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="login-content login-content-fluid" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <a href="/"><img src={window.location.origin + "/assets/img/logo.png"} /></a>
                            {
                                this.state.is_loading ?
                                    <>
                                        <div className="ui active inline loader inverted" ></div>
                                        <h6 className="mt-3 sub-heading text-center">กำลังโหลด</h6>
                                    </>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className="title d-inline-block mb-2" >
                                            <h6 className="mb-3 sub-heading text-center">ยินดีต้อนรับสู่เว็บไซด์ SKITz</h6>
                                            <h3 className="mb-1 heading text-center">รหัส OTP ของฉันคือ</h3>
                                            <p className="text text-center">ส่งไปที่ {this.state.phoneNumber}</p>
                                        </div>
                                        <form
                                            className="login-content mt-0"
                                            style={{
                                                width: 400
                                            }}
                                            ref={(ref) => this.$form = window.$(ref)}
                                            // style={{ padding: 15, height: 705, overflowY: 'auto' }}
                                            onSubmit={async (e) => {
                                                e.preventDefault();
                                                if (this.submit.hasClass('loading')) { return false; }
                                                this.submit.addClass('loading');
                                                let data = Helper.getFormInputObject(this.$form);
                                                this.onSubmit(data.otp);

                                            }}
                                        >
                                            <div className="form-group">
                                                <label>กรอกรหัส OTP 6 หลักที่คุณได้รับ<small style={{ color: 'red' }}>*</small></label>
                                                <InputMask
                                                    style={{ letterSpacing: '2px' }}
                                                    mask="9  9  9  9  9  9"
                                                    className="form-control text-center"
                                                    name="otp"
                                                    required={true}
                                                />
                                                <div className='d-flex'>
                                                    <p className="text mr-2">
                                                        หากท่านไม่ได้รับข้อความกรุณากด
                                                    </p>
                                                    <span
                                                        style={{ color: 'tomato' }}
                                                        ref={(ref) => { this.sendOtp = window.$(ref); }}
                                                        className="otp-again"
                                                        onClick={() => {
                                                            this.callOtp();
                                                        }}
                                                    >ส่งใหม่</span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">

                                                <div id="phone-sign-in-recaptcha"></div>
                                                <button
                                                    ref={(ref) => {
                                                        this.submit = window.$(ref);
                                                    }}
                                                    type="submit"
                                                    className="btn btn-lg rounded-pill main-btn inverted">
                                                    เข้าสู่ระบบ <i className='fas fa-sign-in'></i>
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}