import httpClient, { skitz_api_host } from './HttpClient'
import httpClientCustom from './HttpClientCustom'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/artist`;
export const getArtist = async (id) => {
    const url = `${baseUrl}/${id}`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const createArtist = async (data) => {
    const url = `${baseUrl}`;
    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const updateArtist = async (data) => {
    const url = `${baseUrl}`;
    try {
        const res = await httpClient.put(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getLives = async (filter) => {
    let url = `${baseUrl}/lives/list`;
    if (filter) {
        url += `?${window.$.param(filter)}`
    }
    try {
        const res = await httpClientCustom.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getLiveRequestList = async (id, filter) => {
    let url = `${baseUrl}/lives/${id}/request/list`;
    if (filter) {
        url += `?${window.$.param(filter)}`
    }
    try {
        const res = await httpClientCustom.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}