import React from 'react';
import Header from './../Include/Header';
import Footer from './../Include/Footer';
import Social from './../Include/Social';
import { Link } from 'react-router-dom'

export default class Index extends React.Component {
    render() {
        return (
            <div className='bg-new'>
                <Social />
                <IndexHeader />
                <AboutContent />
                {/* <BannerContent /> */}
                <Footer />
            </div>
        )
    }
}

class IndexHeader extends React.Component {
    render() {
        return (
            <div className='header-content index-page' style={{ position: 'relative' }}>
                <Header />
                <div className='container' style={{ position: 'relative' }}>
                    <div className='row pb-5'>
                        <div className='col-12 col-md col-left'>
                            <div className='slide-left d-flex justify-content-center'>
                                <img src={"./assets/images/design/boy.png"} className='image-boy' />
                                <div className='d-flex flex-column'>

                                    <img src={"./assets/images/design/qrcode.png"} className='image-qr-code' />
                                    <Link to="/download" className='btn login-btn mt-3' style={{ fontSize: 25 }}>Download</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-5 col-center'>
                            <div className='slide-center d-flex flex-column justify-content-center'>
                                <img src={"./assets/images/design/girl.png"} className='image-girl' />
                                <div className='d-flex justify-content-center mt-3'>
                                    <a className='mr-3' href="https://play.google.com/store/apps/details?id=com.banpuen.user" target="_blank">
                                        <img src={"./assets/images/design/googleplay.png"} className='image-googleplay' />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/skitz-chat-metaverse/id1531519059" target="_blank">
                                        <img src={"./assets/images/design/appstore.png"} className='image-appstore' />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 col-right' >
                            <div className='slide-right'>

                                <div className='slide-text-gradient'>SKITz Application</div>
                                <p>
                                    หาเพื่อนตามสถานที่ต่างๆ
                                    ใกล้ๆคุณด้วย "SKITz"
                                    รู้จักเพื่อนใหม่ ในสถานที่ที่คุณอยู่
                                </p>
                                <a className='btn login-btn mt-3' style={{ fontSize: 25 }}>Detail</a>
                                <div>
                                    <img src={"./assets/images/design/girl-car.png"} className='image-girl-car' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class AboutContent extends React.Component {
    render() {
        return (
            <>
                <div className='about-content'>
                    <p className='text-center text-white'>
                        ถ้าคุณเบื่อบรรยกาศสถานที่เเบบเดิมๆ ชอบใครต้องรวบรวมความกล้า เดินไปขอไลน์ หรือ จะขอเพลงให้ใคร<br />
                        ก็ต้องเขียนใส่กระดาษ ดูเมนูอาหารก็ต้องเรียกพนักงาน เเต่สิ่งเหล่านี้จะหมดไป <br />
                        เพราะ เเอป SKITz จะทำให้ทุกเรื่องเป็นเรื่องง่ายเเค่ปลายนิ้ว
                    </p>
                </div>
                <div className='d-flex chat-content pt-5 pb-5' style={{ position: 'relative' }}>
                    <img src={"./assets/images/design/phone.png"} className='image-phone' style={{ maxWidth: '100%' }} />
                    <div className='image-chat-pos'>
                        <img src={"./assets/images/design/chat.png"} className='image-chat' />
                    </div>
                </div>
                <div className='container about-info' style={{ paddingBottom: 100 }}>
                    <div className='row'>
                        <div className='col-12 col-md-5 resp-hide'>
                            <img src={"./assets/images/design/man.png"} className='image-man' style={{ maxWidth: '100%' }} />
                        </div>
                        <div className='col' style={{ paddingTop: 50 }}>
                            <div className='slide-text-gradient'>SKITz Application</div>
                            <p className='text-white '>
                                นอกจากนี้เพื่อให้ทันกับโลกเเละเทคโนโลยี่ที่เปลี่ยนไป SKITz<br />
                                ได้เชื่อมต่อกับเทคโนโลยี Blockchain ด้วยการใช้ประโยชน์<br />
                                จากคุณสมบัติ Non-Fungible Token (NFT) เพื่อสร้าง<br />
                                Collectible Card, Discount Card เเละ Collection<br />
                                (Profile, ICON, Emoji, MEM ฯ) เเละองค์ประกอบ<br />
                                Play to Earn ผู้ใช้งานสามารถได้รับ Token จากการใช้งาน<br />
                                บน SKITz จากการทำภาระกิจต่างๆบน App SKITz หรือ<br />
                                รับ Airdrop
                            </p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

class BannerContent extends React.Component {
    constructor() {
        super();
        this.state = {
            list: [
                {
                    image: './assets/images/design/banner-phone.png',
                    image_style: { maxWidth: '58%', marginTop: -35 },
                    title: 'Activities',
                    description: 'กิจกรรมต่างๆภายในแอป',
                },
                {
                    image: './assets/images/design/banner-bollon.png',
                    image_style: { maxWidth: '58%', marginTop: -25 },
                    title: 'Airdrops',
                    description: 'ตามสถานที่ต่างๆเมื่อ Check in',
                },
                {
                    image: './assets/images/design/banner-game.png',
                    image_style: { maxWidth: '58%', marginTop: -17 },
                    title: 'Mini-Game',
                    description: 'มินิเกม',
                },
                {
                    image: './assets/images/design/banner-money.png',
                    image_style: { maxWidth: '75%', marginTop: 25, marginLeft: 25 },
                    title: 'Marketplace',
                    description: 'แลกเลี่ยน AFT บนระบบ Marketplace',
                }
            ]
        }
    }
    render() {
        return (
            <div className='banner-home container pb-5'>
                <div className="row" style={{ marginLeft: -200, marginRight: -200 }}>
                    {
                        this.state.list.map((item, item_i) => {
                            return (
                                <div key={item_i} className="col col-6 col-md-3 pl-0 pr-0">
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <div className='banner-frame'>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <img src={item.image} style={{ ...item.image_style }} />
                                            </div>
                                        </div>
                                        <div className='banner-text mt-3' style={{ width: '100%' }}>
                                            <div className='slide-text-gradient'>{item.title}</div>
                                            <p className='text-white' style={{ fontSize: 20 }}>
                                                {item.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

