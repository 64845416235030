import React from 'react';
export default class PolicyDetail extends React.Component {
    render() {
        return (
            <>
                <h5 style={{ fontWeight: 'bold', textAlign: 'center' }}>นโยบายความเป็นส่วนตัวสำหรับลูกค้า</h5>
                บริษัท ยูเอ็กซ์ดี (ไทยแลนด์) จำกัด ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของคุณ โดยนโยบายความเป็นส่วนตัวฉบับนี้ได้อธิบายแนวปฏิบัติเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงสิทธิต่าง ๆ ของเจ้าของข้อมูลส่วนบุคคล ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >การเก็บรวบรวมข้อมูลส่วนบุคคล</h5>
                เราจะเก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับโดยตรงจากคุณผ่านช่องทาง ดังต่อไปนี้
                <ul style={{ marginBottom: 0 }}>
                    <li>การสมัครสมาชิก</li>
                    <li>โทรศัพท์</li>
                    <li>อีเมล</li>
                    <li>Facebook Login</li>
                    <li>Google Login</li>
                    <li>Apple Login</li>
                    <li>เพศ</li>
                    <li>การศึกษา</li>
                    <li>อาชีพ</li>
                </ul>
                เราอาจเก็บรวบรวมข้อมูลส่วนบุคคลของคุณที่เราเข้าถึงได้จากแหล่งอื่น เช่น เสิร์ชเอนจิน โซเชียลมีเดีย หน่วยงานราชการ บุคคลภายนอกอื่น ๆ เป็นต้น

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >ประเภทข้อมูลส่วนบุคคลที่เก็บรวบรวม</h5>
                <strong>ข้อมูลส่วนบุคคล</strong> เช่น ชื่อ นามสกุล อายุ วันเดือนปีเกิด สัญชาติ เลขประจำตัวประชาชน หนังสือเดินทาง เป็นต้น<br />
                <strong>ข้อมูลการติดต่อ</strong> เช่น ที่อยู่ หมายเลขโทรศัพท์ อีเมล เป็นต้น<br />
                <strong>ข้อมูลบัญชี</strong> เช่น บัญชีผู้ใช้งาน ประวัติการใช้งาน เป็นต้น<br />
                <strong>หลักฐานแสดงตัวตน</strong> เช่น สำเนาบัตรประจำตัวประชาชน สำเนาหนังสือเดินทาง เป็นต้น<br />
                <strong>ข้อมูลการทำธุรกรรมและการเงิน</strong> เช่น ประวัติการสั่งซื้อ รายละเอียดบัตรเครดิต บัญชีธนาคาร เป็นต้น<br />
                <strong>ข้อมูลทางเทคนิค</strong> เช่น IP address, Cookie ID, ประวัติการใช้งานเว็บไซต์ (Activity Log) เป็นต้น<br />
                <strong>ข้อมูลอื่น ๆ</strong> เช่น รูปภาพ ภาพเคลื่อนไหว และข้อมูลอื่นใดที่ถือว่าเป็นข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล<br />
                เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลอ่อนไหว ดังต่อไปนี้ เมื่อเราได้รับความยินยอมโดยชัดแจ้งจากคุณ เว้นแต่กฎหมายกำหนดให้ทำได้<br />
                <ul style={{ marginBottom: 0 }}>
                    <li>เชื้อชาติ</li>
                    <li>เผ่าพันธุ์</li>
                    <li>พฤติกรรมความสนใจทางเพศ</li>
                    <li>สถานภาพส่วนตัว</li>
                </ul>
                ข้อมูลอื่นใดที่กระทบต่อข้อมูลส่วนบุคคลของคุณตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด<br />
                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >ผู้เยาว์</h5>
                หากคุณมีอายุต่ำกว่า 20 ปีหรือมีข้อจำกัดความสามารถตามกฎหมาย เราอาจเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เราอาจจำเป็นต้องให้พ่อแม่หรือผู้ปกครองของคุณให้ความยินยอมหรือที่กฎหมายอนุญาตให้ทำได้ หากเราทราบว่ามีการเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้เยาว์โดยไม่ได้รับความยินยอมจากพ่อแม่หรือผู้ปกครอง เราจะดำเนินการลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเรา
                <br />
                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >วิธีการเก็บรักษาข้อมูลส่วนบุคคล</h5>
                เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์
                <br />
                เราเก็บรักษาข้อมูลส่วนบุคคลของคุณ ดังต่อไปนี้
                <ul style={{ marginBottom: 0 }}>
                    <li>ผู้ให้บริการเซิร์ฟเวอร์ในต่างประเทศ</li>
                </ul>
                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >การประมวลผลข้อมูลส่วนบุคคล</h5>
                เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้
                <ul style={{ marginBottom: 0 }}>
                    <li>เพื่อสร้างและจัดการบัญชีผู้ใช้งาน</li>
                    <li>เพื่อการบริหารจัดการภายในบริษัท</li>
                    <li>เพื่อการตลาดและการส่งเสริมการขาย</li>
                    <li>เพื่อการบริการหลังการขาย</li>
                    <li>เพื่อรวบรวมข้อเสนอแนะ</li>
                    <li>เพื่อชำระค่าสินค้าหรือบริการ</li>
                    <li>เพื่อปฏิบัติตามข้อตกลงและเงื่อนไข (Terms and Conditions)</li>
                </ul>
                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >การเปิดเผยข้อมูลส่วนบุคคล</h5>
                เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณให้แก่ผู้อื่นภายใต้ความยินยอมของคุณหรือที่กฎหมายอนุญาตให้เปิดเผยได้ ดังต่อไปนี้
                <br />
                <strong>การบริหารจัดการภายในองค์กร</strong>
                <br />
                เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณภายในบริษัทเท่าที่จำเป็นเพื่อปรับปรุงและพัฒนาสินค้าหรือบริการของเรา เราอาจรวบรวมข้อมูลภายในสำหรับสินค้าหรือบริการต่าง ๆ ภายใต้นโยบายนี้เพื่อประโยชน์ของคุณและผู้อื่นมากขึ้น

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >ระยะเวลาจัดเก็บข้อมูลส่วนบุคคล</h5>
                เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตามระยะเวลาที่จำเป็นในระหว่างที่คุณเป็นลูกค้าหรือมีความสัมพันธ์อยู่กับเราหรือตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับนโยบายฉบับนี้ ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้น หากมีกฎหมายกำหนดไว้ เราจะลบ ทำลาย หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้ เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >สิทธิของเจ้าของข้อมูลส่วนบุคคล</h5>
                ภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล คุณมีสิทธิในการดำเนินการดังต่อไปนี้
                <br />
                <strong>สิทธิขอถอนความยินยอม (right to withdraw consent)</strong> หากคุณได้ให้ความยินยอม เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ ไม่ว่าจะเป็นความยินยอมที่คุณให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น คุณมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดเวลา
                <br />
                <strong>สิทธิขอเข้าถึงข้อมูล (right to access)</strong> คุณมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลของคุณที่อยู่ในความรับผิดชอบของเราและขอให้เราทำสำเนาข้อมูลดังกล่าวให้แก่คุณ รวมถึงขอให้เราเปิดเผยว่าเราได้ข้อมูลส่วนบุคคลของคุณมาได้อย่างไร
                <br />
                <strong>สิทธิขอถ่ายโอนข้อมูล (right to data portability)</strong> คุณมีสิทธิขอรับข้อมูลส่วนบุคคลของคุณในกรณีที่เราได้จัดทำข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบให้สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ รวมทั้งมีสิทธิขอให้เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นเมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ และมีสิทธิขอรับข้อมูลส่วนบุคคลที่เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค
                <br />
                <strong>สิทธิขอคัดค้าน (right to object)</strong> คุณมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในเวลาใดก็ได้ หากการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณที่ทำขึ้นเพื่อการดำเนินงานที่จำเป็นภายใต้ประโยชน์โดยชอบด้วยกฎหมายของเราหรือของบุคคลหรือนิติบุคคลอื่น โดยไม่เกินขอบเขตที่คุณสามารถคาดหมายได้อย่างสมเหตุสมผลหรือเพื่อดำเนินการตามภารกิจเพื่อสาธารณประโยชน์
                <br />
                <strong>สิทธิขอให้ลบหรือทำลายข้อมูล (right to erasure/destruction)</strong> คุณมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของคุณหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวคุณได้ หากคุณเชื่อว่าข้อมูลส่วนบุคคลของคุณถูกเก็บรวบรวม ใช้ หรือเปิดเผยโดยไม่ชอบด้วยกฎหมายที่เกี่ยวข้องหรือเห็นว่าเราหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้ หรือเมื่อคุณได้ใช้สิทธิขอถอนความยินยอมหรือใช้สิทธิขอคัดค้านตามที่แจ้งไว้ข้างต้นแล้ว
                <br />
                <strong>สิทธิขอให้ระงับการใช้ข้อมูล (right to restriction of processing)</strong> คุณมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลชั่วคราวในกรณีที่เราอยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิขอแก้ไขข้อมูลส่วนบุคคลหรือขอคัดค้านของคุณหรือกรณีอื่นใดที่เราหมดความจำเป็นและต้องลบหรือทำลายข้อมูลส่วนบุคคลของคุณตามกฎหมายที่เกี่ยวข้องแต่คุณขอให้เราระงับการใช้แทน
                <br />
                <strong>สิทธิขอให้แก้ไขข้อมูล (right to rectification)</strong> คุณมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของคุณให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                <br />
                <strong>สิทธิร้องเรียน (right to lodge a complaint)</strong> คุณมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง หากคุณเชื่อว่าการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง
                <br />
                คุณสามารถใช้สิทธิของคุณในฐานะเจ้าของข้อมูลส่วนบุคคลข้างต้นได้ โดยติดต่อมาที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราตามรายละเอียดท้ายนโยบายนี้ เราจะแจ้งผลการดำเนินการภายในระยะเวลา 30 วัน นับแต่วันที่เราได้รับคำขอใช้สิทธิจากคุณ ตามแบบฟอร์มหรือวิธีการที่เรากำหนด ทั้งนี้ หากเราปฏิเสธคำขอเราจะแจ้งเหตุผลของการปฏิเสธให้คุณทราบผ่านช่องทางต่าง ๆ เช่น ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >การโฆษณาและการตลาด</h5>
                เพื่อประโยชน์ในการได้รับสินค้าหรือบริการของเรา เราใช้ข้อมูลของคุณเพื่อวิเคราะห์และปรับปรุงสินค้าหรือบริการ และทำการตลาดผ่าน Google, Facebook, pixel tracking code และอื่น ๆ เราใช้ข้อมูลดังกล่าวเพื่อให้สินค้าหรือบริการเหมาะสมกับคุณ

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >เทคโนโลยีติดตามตัวบุคคล (Cookies)</h5>
                เพื่อเพิ่มประสบการณ์การใช้งานของคุณให้สมบูรณ์และมีประสิทธิภาพมากขึ้น เราใช้คุกกี้ (Cookies)หรือเทคโนโลยีที่คล้ายคลึงกัน เพื่อพัฒนาการเข้าถึงสินค้าหรือบริการ โฆษณาที่เหมาะสม และติดตามการใช้งานของคุณ เราใช้คุกกี้เพื่อระบุและติดตามผู้ใช้งานเว็บไซต์และการเข้าถึงเว็บไซต์ของเรา หากคุณไม่ต้องการให้มีคุกกี้ไว้ในคอมพิวเตอร์ของคุณ คุณสามารถตั้งค่าบราวเซอร์เพื่อปฏิเสธคุกกี้ก่อนที่จะใช้เว็บไซต์ของเราได้
                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >การรักษาความมั่งคงปลอดภัยของข้อมูลส่วนบุคคล</h5>
                เราจะรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของคุณไว้ตามหลักการ การรักษาความลับ (confidentiality) ความถูกต้องครบถ้วน (integrity) และสภาพพร้อมใช้งาน (availability) ทั้งนี้ เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผย นอกจากนี้เราจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ (administrative safeguard) มาตรการป้องกันด้านเทคนิค (technical safeguard) และมาตรการป้องกันทางกายภาพ (physical safeguard) ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access control)
                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >การแจ้งเหตุละเมิดข้อมูลส่วนบุคคล</h5>
                ในกรณีที่มีเหตุละเมิดข้อมูลส่วนบุคคลของคุณเกิดขึ้น เราจะแจ้งให้สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลทราบโดยไม่ชักช้าภายใน 72 ชั่วโมง นับแต่ทราบเหตุเท่าที่สามารถกระทำได้ ในกรณีที่การละเมิดมีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของคุณ เราจะแจ้งการละเมิดให้คุณทราบพร้อมกับแนวทางการเยียวยาโดยไม่ชักช้าผ่านช่องทางต่าง ๆ เช่น เว็บไซต์ ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</h5>
                เราอาจแก้ไขเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา
                <br />
                นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 31 พฤษภาคม 2565
                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >นโยบายความเป็นส่วนตัวของเว็บไซต์อื่น</h5>
                นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับการเสนอสินค้า บริการ และการใช้งานบนเว็บไซต์สำหรับลูกค้าของเราเท่านั้น หากคุณเข้าชมเว็บไซต์อื่นแม้จะผ่านช่องทางเว็บไซต์ของเรา การคุ้มครองข้อมูลส่วนบุคคลต่าง ๆ จะเป็นไปตามนโยบายความเป็นส่วนตัวของเว็บไซต์นั้น ซึ่งเราไม่มีส่วนเกี่ยวข้องด้วย

                <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >รายละเอียดการติดต่อ</h5>
                หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้ รวมถึงการขอใช้สิทธิต่าง ๆ คุณสามารถติดต่อเราหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราได้ ดังนี้
                <br />
                <br />
                <strong>ผู้ควบคุมข้อมูลส่วนบุคคล</strong>
                <br />
                บริษัท ยูเอ็กซ์ดี (ไทยแลนด์) จำกัด
                <br />
                50/804 หมู่ที่ 9 บางพูด ปากเกร็ด นนทบุรี 11120
                <br />
                อีเมล support@skitz.com
                <br />
                เว็บไซต์ https://www.skitz.com/
                <br />
                หมายเลขโทรศัพท์ 0816711911
                <br />
                เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                <br />
                อัฐกร อิทธิเตชสี
                <br />
                50/804 หมู่ที่ 9 บางพูด ปากเกร็ด นนทบุรี 11120
                <br />
                อีเมล attakorn@skitz.com
                {/* {
                            platform ?
                                <>
                                    <h5 style={{ marginTop: 10, fontWeight: 'bold' }} >About auto subscription</h5>
                                    1. หากผู้ใช้งานเคยสมัคร SKItz Premium กับทางเรา ผู้ใช้งานสามารถกด Restore Purchases ได้ที่หน้า SKITz Premium เพื่อต่ออายุอัตโนมัติ โดยไม่มีการเรียกเก็บเงินเพิ่ม
                                    {
                                        platform == "iOS" ?
                                            <>
                                                <br />2. เมื่อมีการเปิดใช้งานสมาชิก Premium จะได้รับการต่ออายุโดยอัตโนมัติ 24 ชั่วโมงก่อนที่ช่วงเวลาปัจจุบันจะหมดอายุ โดยทำการขยายระยะเวลาที่เกี่ยวเนื่องให้คุณ
                                                <br />3.บริการต่ออายุอัตโนมัติในบัญชี Apple ของคุณให้บริการโดย Apple ดังนั้น Skitz จึงไม่สามารถยกเลิกให้คุณได้โดยตรง คุณสามารถเข้าสู่ระบบบัญชี Apple บนอุปกรณ์ IOS ของคุณเพื่อยกเลิกโดยทำตามขั้นตอนดังต่อไปนี้ :
                                                <div style={{ backgroundColor: '#f0f0f0', padding: 10, borderRadius: 5, marginTop: 5, marginBottom: 5 }}>
                                                    <div>ไปที่การตั้งค่า - iTuned Store และ App Store - ป้อน Apple ID ของคุณ - การตั้งค่าบัญชี - การสมัครสมาชิก - เลือก Skitz - ยกเลิก</div>
                                                </div>
                                                4.หากคุณต้องการยกเลิกการสมัครสมาชิกโปรดปิดการต่ออายุอัตโนมัติอย่างน้อย 24 ชั่วโมงก่อนสิ้นสุดระยะเวลาชำระเงินไว้แล้วในปัจจุบัน
                                                <br />5.เนื่องจากข้อจำกัดของ Apple ID ราคาจริงที่ผู้ใช้ IOS ชำระจะเป็นไปตามราคาจริงที่แสดงบน Apple Pay
                                            </>
                                            : null
                                    }
                                    {
                                        platform == "AndroidOS" ?
                                            <>
                                                <br />2.หากผู้ใช้งานเคยสมัคร SKItz Premium กับทางเรา ผู้ใช้งานสามารถกด Restore Purchases ด้านล่างเพื่อต่ออายุอัตโนมัติ
                                                <br />3.เข้าไปที่แอป PlayStore และกดที่รูปโปรไฟล์ของคุณ
                                                <br />4.เลือก การชำระเงินและการสมัครใช้บริการ (Payment and Subsciptions)
                                                <br />5.เลือก การสมัครใช้บริการ (Subscriptions)
                                                <br />6.กดที่บริการที่ต้องการยกเลิก
                                                <br />7.ตรวจสอบรายละเอียด และกด ยกเลิกการสมัครรับข้อมูล (Cancel Subscription)
                                                <br />8.เลือกเหตุผลที่ยกเลิกบริการ
                                                <br />9.ระบบจะยกเลิกการสมัครใช้บริการให้คุณ
                                            </>
                                            : null
                                    }
                                </>
                                : null
                        } */}
            </>
        )
    }
}