import React from "react";
import Header from "../Include/Header";
import Footer from "../Include/Footer";
export default class Contact extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <div className="card-box">
                        <h2 style={{ textAlign: 'center' }}>ติดต่อเรา</h2>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}