import React from "react";
import PolicyDetail from './../Components/PolicyDetail'
export default class ApplicationPolicy extends React.Component {
    getMobileOperatingSystem() {

        let type = new window.MobileDetect(window.navigator.userAgent)
        return type.os();
        // return false;
    }
    componentDidMount() {
        window.$('#cookieWow').remove();
        window.$('body').css("background", "white")
        window.$('html').css("background", "white")
    }
    render() {
        let platform = this.getMobileOperatingSystem();
        return (
            <>
                <div className="container" style={{ padding: 0, width: '100%' }}>
                    <div className="card-box" style={{ padding: 15, fontSize: 14 }}>
                        <div style={{ width: '100%', height: 30 }}></div>
                        <PolicyDetail />
                    </div>
                </div>
            </>
        )
    }
}