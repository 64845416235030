import React from "react";
import Header from "../Include/Header";
import Footer from "../Include/Footer";
import CookieDetail from "../Components/CookieDetail";
export default class CookiePolicy extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <div className="card-box">
                        <CookieDetail />
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}