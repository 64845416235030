import React from 'react';
import { Redirect } from 'react-router-dom';
import { Helper } from '../../Helper';
const axios = require('axios').default;

export default class Lobby extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            redirect: false,
            currentUser: false,
        }
    }
    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let currentArtist = Helper.getCookie('currentArtist') ? JSON.parse(Helper.getCookie("currentArtist")) : false;
        if (currentArtist) {
            let res = await axios.get(`${window.api_host}/artist?uid=${currentArtist.fireBaseUid}`);
            if (res.data.success) {
                currentArtist.artist = res.data.artist;
            }
        }
        this.setState({
            currentUser: currentArtist,
            is_loading: false,
        })
    }

    signOut() {

        Helper.setCookie("currentUser", "");
        this.setState({
            redirect: `/user/login?type=artist&storeUid=${this.state.currentUser.currentStoreUid}`
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        let artist = this.state.currentUser && this.state.currentUser.artist ? this.state.currentUser.artist : false;
        return (
            <div className="bg-1" >
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="login-content login-content-fluid" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <a href="/"><img src={window.location.origin + "/assets/img/logo.png"} /></a>
                            {
                                this.state.is_loading ?
                                    <>
                                        <div className="ui active inline loader inverted" ></div>
                                        <h6 className="mt-3 sub-heading text-center">กำลังโหลด</h6>
                                    </>
                                    : null
                            }
                            {
                                !this.state.is_loading
                                    && this.state.currentUser ?
                                    <>
                                        <div className='logedin abs_pos'>
                                            <div className='d-flex align-items-center'>
                                                <button
                                                    onClick={() => {
                                                        this.signOut();
                                                    }}
                                                    type="button"
                                                    className="btn btn-sm rounded-pill main-btn inverted"
                                                >ออกจากระบบ <i className="fas fa-sign-out"></i></button>
                                            </div>
                                        </div>
                                        <div className="title d-inline-block mb-2" >
                                            <h6 className="mb-3 sub-heading text-center">ยินดีต้อนรับสู่เว็บไซด์ SKITz</h6>
                                            <h3 className="mb-1 heading text-center">นักดนตรี</h3>
                                            {/* <p className="text text-center">กรุณากรอกเบอร์โทรศัพท์</p> */}
                                        </div>
                                        <div className='container mt-4'>
                                            <div className='row justify-content-center'>
                                                <div className='col-12 col-md-4'>
                                                    <div>
                                                        <div className="text-center d-flex justify-content-center align-items-center mb-2">
                                                            <div className="profile-user-img img-frame img-fluid img-circle align-items-center">
                                                                <img
                                                                    src={artist && artist.photoURL ? artist.photoURL : "/assets/images/no_img.png"}
                                                                    alt="User profile" />
                                                            </div>
                                                        </div>
                                                        <h3 className="profile-username text-center" style={{ color: '#fff' }}>{artist.displayName || '-'}</h3>
                                                        <div className='mb-4' style={{ display: `flex`, justifyContent: 'center', alignItems: 'center' }}>
                                                            <div style={{ padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: 'rgba(255,255,255,.5)', borderRadius: 5, }}>
                                                                <span style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, padding: '0 10px', alignItems: 'center', color: 'white' }}>
                                                                    {Helper.numberFormat(this.state.currentUser.currentCoin)}
                                                                    <img src={"/assets/img/skitzcoin.png"} style={{ width: 20, height: 20, marginLeft: 5 }} />
                                                                </span>
                                                                <span style={{ display: 'flex', flex: 1, padding: '0 10px', borderLeft: '1px solid #f4f4f4', alignItems: 'center', color: 'white' }}>
                                                                    {Helper.numberFormat(this.state.currentUser.currentPoint)}
                                                                    <img src={"/assets/img/skitzpoint.png"} style={{ width: 20, height: 20, marginLeft: 5 }} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='mb-5 d-flex justify-content-center align-items-center'>
                                                            <button
                                                                onClick={() => {
                                                                 
                                                                }}
                                                                type="button"
                                                                className="btn btn-lg rounded-pill login-btn inverted"
                                                            >
                                                                ขึ้นแสดง <i className="fas fa-music"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }
                            {
                                !this.state.is_loading
                                    && !this.state.currentUser ?
                                    <>
                                        <div className="title d-inline-block mb-2" >
                                            <h6 className="mb-3 sub-heading text-center">ยินดีต้อนรับสู่เว็บไซด์ SKITz</h6>
                                            <h3 className="mb-1 heading text-center">นักดนตรี</h3>
                                            <p className="text text-center">ไม่พบข้อมูลร้าน</p>
                                        </div>
                                        <h3 className="mb-3 heading text-center text-danger">ไม่สามารถเข้าสู่ระบบได้</h3>
                                        <p className="text text-center">กรุณาแสกน QR code สำหรับเข้าร้านใหม่อีกครั้ง</p>
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}