import React from 'react';
import { Helper } from '../../Helper';
import { db, auth } from '../../firebase';
import CryptoJS from 'crypto-js';
import { Redirect } from "react-router-dom";
import { postPayment } from '../../services/PaymentServices';
import { getAllPackage } from '../../services/CoinPackageServices';
import { getUser } from '../../services/UserServices';
import { getChannels, getPacakgeChannels } from '../../services/ChannelsServices'
import InputMask from 'react-input-mask';
const axios = require('axios').default;
window.wedev = {
    secretKey: process.env.REACT_APP_WEDEV_SECRET_KEY,
    merchantId: process.env.REACT_APP_WEDEV_MERCHANT_ID,
    merchantName: process.env.REACT_APP_WEDEV_MERCHANT_NAME,
    merchantCompany: process.env.REACT_APP_WEDEV_MERCHANT_COMPANY,
}
export default class CoinPackage extends React.Component {
    constructor() {
        super();
        let years = [];
        let i;
        let nowYear = new Date().getFullYear()
        for (i = nowYear; i <= nowYear + 10; i++) {
            years.push(i)
        }
        this.state = {
            is_loading: true,
            channels: [],
            packages: [],
            list: [],
            selected_channel: false,
            selected_pacakge: false,
            redirect: false,
            currentUser: false,
            postForm: {},
            months: [
                '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
            ],
            years: [...years]
        }
    }

    componentDidMount() {
        let currentUser = Helper.getCookie('currentUser') ? JSON.parse(Helper.getCookie("currentUser")) : false;
        if (currentUser) {
            this.fetchChannel();
        } else {
            this.setState({
                redirect: '/user/login',
            })
        }
    }
    componentDidUpdate() {
        let currentUser = Helper.getCookie('currentUser') ? JSON.parse(Helper.getCookie("currentUser")) : false;
        if (!currentUser) {
            this.setState({
                redirect: '/user/login',
            })
        }
    }
    async fetchChannel() {
        let res = await getChannels();
        let get_post_data = await this.setDefault();
        let currentUser = get_post_data.currentUser;
        let postForm = get_post_data.postForm;
        let channels = [];
        let is_CP = false;
        if (!res.error) {
            channels = res;
            let get_cp = res.filter((e) => { return e.channelCode == 'CP' })
            is_CP = get_cp.length > 0 ? get_cp[0] : false;
        }
        this.setState({
            currentUser: currentUser,
            postForm: postForm,
            channels: channels,
            is_CP: is_CP,
            is_loading: false,
        })
    }
    async setDefault() {
        let currentUser = Helper.getCookie('currentUser') ? JSON.parse(Helper.getCookie("currentUser")) : false;
        window.uid = currentUser.uid;
        let user = await getUser(currentUser.uid);
        if (user) {
            currentUser.currentCoin = user.currentCoin;
            currentUser.userImage = user.userImage;
        }
        let postForm = {}
        postForm.merchantId = window.wedev.merchantId;
        postForm.buyerName = currentUser.displayName || '';
        postForm.buyerSurname = ``;
        postForm.buyerEmail = currentUser.email || ``;
        postForm.buyerMobile = currentUser.phoneNumber ? currentUser.phoneNumber.replace('+66', '0') : ``;
        postForm.amount = ``;
        postForm.currencyCode = `THB`;
        postForm.productDescription = ``;
        postForm.merchantReference = ``;
        postForm.redirectUrl = `${window.location.host}/package/payment/`;
        postForm.NotificationUrl = `${process.env.REACT_APP_SKITZ_API}/api/v1/wedev/payment`;
        return {
            postForm: postForm,
            currentUser: currentUser
        };
    }

    async fetchPackageChannel() {
        let id = this.state.selected_channel ? this.state.selected_channel.id : false;
        let packages = [];
        if (id) {
            let res = await getPacakgeChannels(id);
            if (!res.error) {
                packages = res;
            }
        }
        this.setState({
            packages: packages,
            is_loading_package: false,
        })
    }
    async fetch() {
        // let res = await axios.get(`${window.api_host}/coin/package`);

        let res = await getAllPackage();
        let list = [];
        if (!res.error) {
            list = res;
        }
        let currentUser = Helper.getCookie('currentUser') ? JSON.parse(Helper.getCookie("currentUser")) : false;
        let user = await getUser(currentUser.uid);
        if (user) {
            currentUser.currentCoin = user.currentCoin;
            currentUser.userImage = user.userImage;
        }
        let postForm = {}
        postForm.merchantId = window.wedev.merchantId;
        postForm.buyerName = currentUser.displayName || '';
        postForm.buyerSurname = ``;
        postForm.buyerEmail = currentUser.email || ``;
        postForm.buyerMobile = currentUser.phoneNumber ? currentUser.phoneNumber.replace('+66', '0') : ``;
        postForm.amount = ``;
        postForm.currencyCode = `THB`;
        postForm.productDescription = ``;
        postForm.merchantReference = ``;
        postForm.redirectUrl = `${window.location.host}/package/payment/`;
        postForm.NotificationUrl = `${process.env.REACT_APP_SKITZ_API}/api/v1/wedev/payment`;
        // postForm.redirectUrl = ``;
        this.setState({
            currentUser: currentUser,
            is_loading: false,
            list: list,
            postForm: postForm,
        })
    }
    signOut() {
        window.currentUserUid = null;
        Helper.setCookie("currentUser", "");
        this.setState({
            redirect: '/package'
        })
    }
    async onSubmit() {
        let state = this.state;
        if (this.submit.hasClass('loading')) { return; }
        this.submit.addClass('loading');
        if (!state.selected_pacakge) {
            Helper.messageTop({ message: 'กรุณาเลือกแพ็กเกจ' });
            this.submit.removeClass('loading');
            return;
        }
        window.Omise.setPublicKey(process.env.REACT_APP_OMISE_PUBLIC_KEY);
        // window.Omise.setPublicKey(undefined);

        let data = { ...(await this.setDefault()).postForm }
        if (state.selected_channel && state.selected_channel.channelCode && state.selected_channel.channelCode == 'CP') {
            let cardNumber = window.$('[name="cardNumber"]').val();
            let cardName = window.$('[name="cardName"]').val();
            let cardExpiredMonth = window.$('[name="cardExpiredMonth"]').val();
            let cardExpiredYear = window.$('[name="cardExpiredYear"]').val();
            let securityCode = window.$('[name="securityCode"]').val();
            if (!cardName || !cardNumber || !cardExpiredMonth || !cardExpiredYear || !securityCode) {
                Helper.messageTop({ message: 'กรุณากรอกข้อมูลบัตรเครคิด' })
                if (!cardNumber) {
                    window.$('[name="cardNumber"]').focus();
                } else if (!cardName) {
                    window.$('[name="cardName"]').focus();
                } else if (!cardExpiredMonth) {
                    window.$('[name="cardExpiredMonth"]').focus();
                } else if (!cardExpiredYear) {
                    window.$('[name="cardExpiredYear"]').focus();
                } else if (!securityCode) {
                    window.$('[name="securityCode"]').focus();
                }
                this.submit.removeClass('loading');
                return;
            }
            var cardInformation = {
                name: cardName,
                number: cardNumber,
                expiration_month: cardExpiredMonth,
                expiration_year: cardExpiredYear,
                security_code: securityCode
            };
            await window.Omise.createToken('card', cardInformation, async (statusCode, response) => {
                // console.log(statusCode, response)
                if (statusCode === 200) {
                    data.cardToken = response.id;
                    this.onSumbitRedirect(data);
                } else {
                    Helper.messageTop({ message: response.message });
                    this.submit.removeClass('loading');
                    return;
                }
            });
        } else {
            this.onSumbitRedirect(data);
        }
    }
    async onSumbitRedirect(data) {
        let paymentPost = {
            amount: this.state.selected_pacakge.price,
            fireBaseUid: this.state.currentUser.uid,
            payerName: this.state.postForm.buyerName,
            paymentTypeId: 2,
            paymentChannelId: 3
        }
        if (this.state.selected_channel && this.state.selected_channel.channelCode) {
            paymentPost.channelCode = this.state.selected_channel.channelCode;
        }
        // console.log(data);
        // this.submit.removeClass('loading');
        // return;
        let res = await postPayment(paymentPost);
        if (res.error) {
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' });
            this.submit.removeClass('loading');
            return;
        }
        data.amount = this.state.selected_pacakge.price.toFixed(2);
        data.productDescription = `${this.state.postForm.buyerName} เพิ่มเหรียญ ${Helper.numberFormat(this.state.selected_pacakge.coinAmount)} Skitz Coin จากแพ็จเกจ ${this.state.selected_pacakge.name} ในราคา ${Helper.numberFormat(this.state.selected_pacakge.price)} บาท`;
        data.merchantReference = res.transactionNumber;



        let preparePaintext = { ...data };
        delete preparePaintext.checkSum;
        delete preparePaintext.productItemsJson;
        delete preparePaintext.NotificationUrl;
        delete preparePaintext.cardToken;
        //set paintext
        let paintext = ``;
        for (const item of Object.entries(preparePaintext)) {
            // if (item[1]) {
            paintext += item[1];
            // }
        }
        // console.log(paintext)
        // console.log(window.wedev.secretKey)
        // encrypted
        let encrypted = CryptoJS.HmacSHA256(paintext, window.wedev.secretKey).toString();
        data.checkSum = encrypted;
        let productItemsJson = [{
            productName: this.state.selected_pacakge.name,
            price: Helper.numberFormat(this.state.selected_pacakge.price, { decimal: 1 }),
        }];
        data.productItemsJson = JSON.stringify(productItemsJson);
        // if (this.state.selected_channel && this.state.selected_channel.channelCode) {
        //     if (this.state.selected_channel.channelCode == 'CP') {
        //         let cardNumber = window.$('[name="cardNumber"]').val();
        //         let cardName = window.$('[name="cardName"]').val();
        //         let cardExpiredMonth = window.$('[name="cardExpiredMonth"]').val();
        //         let cardExpiredYear = window.$('[name="cardExpiredYear"]').val();
        //         let securityCode = window.$('[name="securityCode"]').val();
        //         if (!cardName || !cardNumber || !cardExpiredMonth || !cardExpiredYear || !securityCode) {
        //             Helper.messageTop({ message: 'กรุณากรอกข้อมูลบัตรเครคิด' })
        //             if (!cardNumber) {
        //                 window.$('[name="cardNumber"]').focus();
        //             } else if (!cardName) {
        //                 window.$('[name="cardName"]').focus();
        //             } else if (!cardExpiredMonth) {
        //                 window.$('[name="cardExpiredMonth"]').focus();
        //             } else if (!cardExpiredYear) {
        //                 window.$('[name="cardExpiredYear"]').focus();
        //             } else if (!securityCode) {
        //                 window.$('[name="securityCode"]').focus();
        //             }
        //             this.submit.removeClass('loading');
        //             return;
        //         } else {
        //             let setCardNumber = cardNumber.replace(/\s/g, '');
        //             data.cardName = cardName;
        //             data.cardNumber = setCardNumber;
        //             data.cardExpiredMonth = cardExpiredMonth;
        //             data.cardExpiredYear = cardExpiredYear;
        //             data.securityCode = securityCode;
        //         }
        //     }
        // }
        // console.log(data);
        this.setState({
            postForm: data
        }, () => {
            this.postForm.trigger('submit');
            // this.submit.removeClass('loading');
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }
        return (
            <div className="bg-1" >
                {
                    this.state.currentUser ?
                        <div className='logedin'>
                            <div className='d-flex align-items-center'>
                                {
                                    this.state.currentUser.userImage ?
                                        <div className='profile-image'><img src={this.state.currentUser.userImage} /></div>
                                        :
                                        <i className='fas fa-user' style={{ marginLeft: 12 }}></i>
                                }
                                <div className='ml-2'>{this.state.currentUser.displayName}</div>
                                <div className='ml-2 coin-box pr-2 pl-2'>{Helper.numberFormat(this.state.currentUser.currentCoin)}<i className='fas fa-coins ml-1'></i></div>
                                <button
                                    onClick={() => {
                                        this.signOut();
                                    }}
                                    type="button"
                                    className="ml-3 btn btn-sm rounded-pill main-btn inverted"
                                >ออกจากระบบ <i className="fas fa-sign-out"></i></button>
                            </div>
                        </div>
                        : null
                }
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="login-content login-content-fluid" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <a href="/"><img src={window.location.origin + "/assets/img/logo.png"} /></a>
                            {
                                this.state.is_loading ?
                                    <>
                                        <div className="ui active inline loader inverted" ></div>
                                        <h6 className="mt-3 sub-heading text-center">กำลังโหลด</h6>
                                    </>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className="title d-inline-block mb-2" >
                                            <h6 className="mb-3 sub-heading text-center">บริการเติมเหรียญ SKITz Coin</h6>
                                            <h3 className="mb-3 heading text-center">เติมเหรียญ</h3>
                                            <p className="text text-center">กรุณาเลือกวิธีการชำระเงินที่ต้องการ</p>
                                        </div>
                                        <div className='container mt-4'>
                                            <div className='row mbl-3'>
                                                {
                                                    this.state.channels.length > 0 ?
                                                        <>
                                                            {
                                                                this.state.is_CP ?
                                                                    <div className='col-12 col-md-4'>
                                                                        <div className={this.state.selected_channel && this.state.selected_channel.channelCode == 'CP' ? "card card-custom card-selected" : "card card-custom"}
                                                                            onClick={() => {
                                                                                if (this.state.selected_channel && this.state.selected_channel.channelCode == 'CP') { return; }
                                                                                this.setState({
                                                                                    selected_channel: this.state.is_CP,
                                                                                    selected_pacakge: false,
                                                                                    is_loading_package: true
                                                                                }, () => {
                                                                                    this.fetchPackageChannel()
                                                                                })
                                                                            }}
                                                                        >
                                                                            <div className='d-flex align-items-center' style={{ borderBottom: '1px solid #ccc', paddingBottom: 5, marginBottom: 10, }}>
                                                                                {
                                                                                    this.state.selected_channel && this.state.selected_channel.channelCode == 'CP' ?
                                                                                        <i className='fas fa-check-circle' style={{ color: 'green', position: 'absolute', top: 10, right: 10 }}></i>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    this.state.is_CP && this.state.is_CP.name ?
                                                                                        <h4 className='mb-0' style={{ fontSize: 18 }}>{this.state.is_CP.name}</h4>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    this.state.is_CP && this.state.is_CP.description ?
                                                                                        <p className='text-mute' style={{ color: '#888' }}>{this.state.is_CP.description}</p>
                                                                                        : null
                                                                                }
                                                                                <div style={{ marginLeft: 'auto' }}>
                                                                                    <img src={this.state.is_CP.icon} style={{ width: 30 }} />
                                                                                </div>
                                                                            </div>

                                                                            <div>
                                                                                <InputMask
                                                                                    mask="9 9 9 9   9 9 9 9   9 9 9 9   9 9 9 9"
                                                                                    name="cardNumber"
                                                                                    id="cardNumber"
                                                                                    className='form-control'
                                                                                    placeholder='Card Number'
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <input name="cardName" id="cardName" className='form-control' placeholder='Card Name' />
                                                                            </div>

                                                                            <div className='d-flex' style={{ margin: '0 -5px' }}>
                                                                                <div style={{ flex: 1, padding: '0 5px' }}>
                                                                                    <select className='form-control' name="cardExpiredMonth" style={{ fontSize: 12 }}>
                                                                                        <option value="">Month</option>
                                                                                        {
                                                                                            this.state.months.map((month) => {
                                                                                                return (
                                                                                                    <option value={month}>{month}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                                <div style={{ flex: 1, padding: '0 5px' }}>
                                                                                    <select className='form-control' name="cardExpiredYear" style={{ fontSize: 12 }}>
                                                                                        <option value="">Year</option>
                                                                                        {
                                                                                            this.state.years.map((year) => {
                                                                                                return (
                                                                                                    <option value={year}>{year}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                                <div style={{ flex: 1, padding: '0 5px' }}>
                                                                                    <input name="securityCode" id="securityCode" className='form-control' placeholder='* * *' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            }
                                                            <div className={!this.state.is_CP ? 'col-12' : 'col-12 col-md-8'}>

                                                                <div className='row'>
                                                                    {
                                                                        this.state.channels.map((data, i) => {
                                                                            let is_selected = this.state.selected_channel && this.state.selected_channel.id === data.id;
                                                                            if (data.channelCode == 'CP') { return null }
                                                                            return (
                                                                                <div className='col-12 col-md-4 col-lg-4 mb-4' key={i}>
                                                                                    <div className={is_selected ? 'card card-custom card-selected' : 'card card-custom'}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                selected_channel: data,
                                                                                                selected_pacakge: false,
                                                                                                is_loading_package: true
                                                                                            }, () => {
                                                                                                this.fetchPackageChannel()
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <div className='d-flex'>
                                                                                            {
                                                                                                is_selected ?
                                                                                                    <i className='fas fa-check-circle' style={{ color: 'green', position: 'absolute', top: 10, right: 10 }}></i>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                data && data.name ?
                                                                                                    <h4 className='mb-0' style={{ fontSize: 18 }}>{data.name}</h4>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                data && data.description ?
                                                                                                    <p className='text-mute' style={{ color: '#888' }}>{data.description}</p>
                                                                                                    : null
                                                                                            }
                                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                                <img src={data.icon} style={{ width: 80 }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        : <div className='d-flex align-items-center mt-0'>
                                                            <h3 className={"mr-1 mb-0 text-white"}>ไม่มีช่องทางการชำระเงิน</h3>
                                                        </div>
                                                }

                                                {
                                                    this.state.list.length > 0 ?
                                                        this.state.list.map((data, i) => {
                                                            let is_selected = this.state.selected_pacakge && this.state.selected_pacakge.id === data.id;
                                                            return (
                                                                <div className='col-12 col-md-6 col-lg-4 mb-4' key={i}>
                                                                    <div className={is_selected ? 'card card-custom card-selected' : 'card card-custom'}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                selected_pacakge: data
                                                                            })
                                                                        }}
                                                                    >
                                                                        {
                                                                            is_selected ?
                                                                                <i className='fas fa-check-circle' style={{ color: 'green', position: 'absolute', top: 10, right: 10 }}></i>
                                                                                : null
                                                                        }
                                                                        {
                                                                            data && data.name ?
                                                                                <h4 className='mb-0'>{data.name}</h4>
                                                                                : null
                                                                        }
                                                                        {
                                                                            data && data.description ?
                                                                                <p className='text-mute' style={{ color: '#888' }}>{data.description}</p>
                                                                                : null
                                                                        }
                                                                        <div>
                                                                            <i className='far fa-gift' style={{ fontSize: 80 }}></i>
                                                                        </div>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h3 className={"mr-1 mb-0"}>฿{Helper.numberFormat(data.price)} </h3> <i className="fas fa-exchange-alt mr-2"></i> <span >{Helper.numberFormat(data.coinAmount)} </span> <i className='fas fa-coin ml-1' style={{ color: '#F8D400' }}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        null
                                                }
                                            </div>
                                            {
                                                !this.state.is_loading_package ?
                                                    this.state.packages.length > 0 ?
                                                        <>
                                                            <div className="title d-inline-block mb-2" >
                                                                <h3 className="mb-3 heading text-center">กรุณาเลือกแพ็กเกจที่ต้องการ</h3>
                                                            </div>
                                                            <div className='row justify-content-center mbl-3'>
                                                                {
                                                                    this.state.packages.map((data, i) => {
                                                                        let is_selected = this.state.selected_pacakge && this.state.selected_pacakge.id === data.id;
                                                                        return (
                                                                            <div className='col-12 col-md-6 col-lg-4 mb-4' key={i}>
                                                                                <div className={is_selected ? 'card card-custom card-selected' : 'card card-custom'}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            selected_pacakge: data
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        is_selected ?
                                                                                            <i className='fas fa-check-circle' style={{ color: 'green', position: 'absolute', top: 10, right: 10 }}></i>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        data && data.name ?
                                                                                            <h4 className='mb-0'>{data.name}</h4>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        data && data.description ?
                                                                                            <p className='text-mute' style={{ color: '#888' }}>{data.description}</p>
                                                                                            : null
                                                                                    }
                                                                                    <div>
                                                                                        <i className='far fa-gift' style={{ fontSize: 80 }}></i>
                                                                                    </div>
                                                                                    <div className='d-flex align-items-center mt-3'>
                                                                                        <h3 className={"mr-1 mb-0"}>฿{Helper.numberFormat(data.price)} </h3> <i className="fas fa-exchange-alt mr-2"></i> <span >{Helper.numberFormat(data.coinAmount)} </span> <i className='fas fa-coin ml-1' style={{ color: '#F8D400' }}></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                        : null : null
                                            }
                                            {
                                                this.state.is_loading_package ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader inverted" ></div>
                                                        <h6 className="mt-3 sub-heading text-center">กำลังโหลดแพ็กเกจ</h6>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                this.state.list.length > 0
                                                    || this.state.packages.length > 0 ?
                                                    <form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            this.onSubmit()
                                                        }}
                                                        className="d-flex justify-content-center"
                                                    >
                                                        <button
                                                            ref={(ref) => {
                                                                this.submit = window.$(ref);
                                                            }}
                                                            type="submit"
                                                            className="btn btn-lg rounded-pill main-btn inverted">
                                                            ชำระเงิน <i className='fas fa-arrow-right'></i>
                                                        </button>
                                                    </form>
                                                    : null
                                            }

                                            <form
                                                ref={(ref) => { this.postForm = window.$(ref) }}
                                                method={"post"}
                                                action={process.env.REACT_APP_WEDEV_POST_PAYMENT}
                                            >
                                                <input type="hidden" name="merchantId" value={window.wedev.merchantId} />

                                                <input type="hidden" name="buyerName" value={this.state.postForm && this.state.postForm.buyerName ? this.state.postForm.buyerName : ''} />
                                                <input type="hidden" name="buyerSurname" value={this.state.postForm && this.state.postForm.buyerSurname ? this.state.postForm.buyerSurname : ''} />
                                                <input type="hidden" name="buyerEmail" value={this.state.postForm && this.state.postForm.buyerEmail ? this.state.postForm.buyerEmail : ''} />
                                                <input type="hidden" name="buyerMobile" value={this.state.postForm && this.state.postForm.buyerMobile ? this.state.postForm.buyerMobile : ''} />
                                                <input type="hidden" name="amount" value={this.state.postForm && this.state.postForm.amount ? this.state.postForm.amount : '0.00'} />
                                                <input type="hidden" name="currencyCode" value={this.state.postForm && this.state.postForm.currencyCode ? this.state.postForm.currencyCode : ''} />
                                                <input type="hidden" name="productDescription" value={this.state.postForm && this.state.postForm.productDescription ? this.state.postForm.productDescription : ''} />
                                                <input type="hidden" name="productItemsJson" value={this.state.postForm && this.state.postForm.productItemsJson ? this.state.postForm.productItemsJson : ''} />
                                                <input type="hidden" name="merchantReference" value={this.state.postForm && this.state.postForm.merchantReference ? this.state.postForm.merchantReference : ''} />
                                                <input type="hidden" name="notificationUrl" value={this.state.postForm && this.state.postForm.NotificationUrl ? this.state.postForm.NotificationUrl : ''} />
                                                <input type="hidden" name="redirectUrl" value={this.state.postForm && this.state.postForm.redirectUrl ? this.state.postForm.redirectUrl : ''} />
                                                <input type="hidden" name="checkSum" value={this.state.postForm && this.state.postForm.checkSum ? this.state.postForm.checkSum : ''} />
                                                {
                                                    this.state.selected_channel && this.state.selected_channel.channelCode ?
                                                        <>
                                                            <input type="hidden" name="channelCode" value={this.state.selected_channel && this.state.selected_channel.channelCode ? this.state.selected_channel.channelCode : ''} />
                                                            {
                                                                this.state.selected_channel.channelCode == "CP" ?
                                                                    <>
                                                                        {/* <input type="hidden" name="cardName" value={this.state.postForm && this.state.postForm.cardName ? this.state.postForm.cardName : ''} />
                                            <input type="hidden" name="cardNumber" value={this.state.postForm && this.state.postForm.cardNumber ? this.state.postForm.cardNumber : ''} />
                                            <input type="hidden" name="cardExpiredMonth" value={this.state.postForm && this.state.postForm.cardExpiredMonth ? this.state.postForm.cardExpiredMonth : ''} />
                                            <input type="hidden" name="cardExpiredYear" value={this.state.postForm && this.state.postForm.cardExpiredYear ? this.state.postForm.cardExpiredYear : ''} />
                                            <input type="hidden" name="securityCode" value={this.state.postForm && this.state.postForm.securityCode ? this.state.postForm.securityCode : ''} /> */}
                                                                        <input type="hidden" name="cardToken" value={this.state.postForm && this.state.postForm.cardToken ? this.state.postForm.cardToken : ''} />
                                                                    </>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                                }
                                            </form>
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}