import React from 'react';
import { Helper } from './../Helper';
import GoogleMap from './../Components/GoogleMap'
// import { Link } from "react-router-dom";
const axios = require('axios').default;

export default class RegisterSuccess extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
            store_types: [],
            provinces: Helper.getAllProvinces(),
            location: false,

        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        this.setState({
            is_loading: false,
        })
    }

    render() {
        // console.log('register render')
        return (
            <div className="bg-1" >
                <div className="login-content login-content-fluid" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={"./assets/img/logo.png"} />
                    {
                        this.state.is_loading ?
                            <>
                                <div className="ui active inline loader inverted" ></div>
                                <h6 className="mt-3 sub-heading text-center">กำลังโหลด</h6>
                            </>
                            : null
                    }
                    {
                        !this.state.is_loading ?
                            <>
                                <div className="title d-inline-block mb-2" >
                                    <h6 className="mb-0 sub-heading text-center">ใช้ SKITz ในสถานที่ของคุณฟรี</h6>
                                    <div className="text-center">

                                        <img src={"./assets/images/location.png"} style={{ maxWidth: '100%', width: 200, marginTop: 20, marginBottom: 30 }} />
                                    </div>
                                    <h3 className="mb-3 heading text-center text-success">ลงทะเบียนสำเร็จ</h3>
                                    <p className="text text-center">การลงทะเบียนสำเร็จแล้ว กรุณารอยืนยันการลงทะเบียนจากทาง SKITz</p>
                                </div>
                                {/* <img src={"./assets/images/check-point.png"}  style={{maxWidth: '100%', width: 300, marginTop: 20}}/> */}
                            </>
                            : null
                    }
                </div>

            </div>
        );
    }
}

