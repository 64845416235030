import React from "react";
import Header from "../Include/Header";
import Footer from "../Include/Footer";
import PolicyDetail from "../Components/PolicyDetail";
export default class Policy extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <div className="card-box">
                        <PolicyDetail />
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}