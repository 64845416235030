import React from "react";
import Header from "../Include/Header";
import Footer from "../Include/Footer";
import { isAndroid, isIOS, isWindows } from 'react-device-detect';
const AndroidURL = "https://play.google.com/store/apps/details?id=com.banpuen.user";
const IOSURL = "https://apps.apple.com/us/app/skitz-chat-metaverse/id1531519059";
export default class DownloadApp extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
                console.log('android')
                window.location.href = AndroidURL;
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                console.log('ios')
                window.location.href = IOSURL;
            }
        }, 1000);
    }
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <div className="card-box">
                        {/* <h2 style={{ textAlign: 'center' }}>Download Application</h2> */}
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                            {
                                isAndroid || isWindows ?
                                    <a href={AndroidURL} className="mx-4" target="_blank">
                                        <img src="./assets/images/design/googleplay.png" className="image-googleplay" />
                                    </a>
                                    : null
                            }
                            {
                                isIOS || isWindows ?
                                    <a href={IOSURL} className="mx-4" target="_blank">
                                        <img src="./assets/images/design/appstore.png" className="image-appstore" />
                                    </a>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}