import React from 'react';
import { Helper } from './../Helper';
import GoogleMap from './../Components/GoogleMap'
// import { Link } from "react-router-dom";
const axios = require('axios').default;

export default class RegisterStore extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
            store_types: [],
            provinces: Helper.getAllProvinces(),
            location: false,

        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let data = {};
        data.uid = this.props.uid || false;
        data.store_type = true;
        data.package_type = true;
        data.province = true;
        let res = await axios.post(window.api_host + '/store', { data: data });
        let store = res.data.response.store || false;
        let store_types = res.data.response.store_type || [];
        let package_types = res.data.response.package_types || [];
        this.setState({
            data: store,
            store_types: store_types,
            package_types: package_types,
            is_loading: false,
        })
    }

    async create(data) {
        try {
            let res = await axios.post(window.api_host + '/register-store', data);
            // console.log(res);
            if (res.data.error) {
                Helper.messageTop({
                    message: res.data.message,
                })
                this.submit.removeClass('loading');
                return;
            }
            if (res.data.success) {
                Helper.messageTop({
                    message: "เพิ่มข้อมูลสำเร็จ",
                    type_class: 'alert-success'
                })
                setTimeout(() => {
                    window.location = '/register-store-success';
                }, 2000)
                // this.submit.removeClass('loading');
            }
        } catch (error) {
            Helper.messageTop({
                message: 'เกิดข้อผิิดพลาด กรุณาลองใหม่อีกครั้ง',
            })
            this.submit.removeClass('loading');
            return;
        }

    }

    render() {
        // console.log('register render')
        return (
            <div className="bg-1" >
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="login-content login-content-fluid" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={"./assets/img/logo.png"} />
                            {
                                this.state.is_loading ?
                                    <>
                                        <div className="ui active inline loader inverted" ></div>
                                        <h6 className="mt-3 sub-heading text-center">กำลังโหลด</h6>
                                    </>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className="title d-inline-block mb-2" >
                                            <h6 className="mb-3 sub-heading text-center">แนะนำสถานที่ของคุณ ขึ้น App SKITz</h6>
                                            <p className="text text-center mb-1">แนะนำสถานที่หรือร้านอาหารของคุณเพื่อให้แสดงบนแอป SKITz พร้อมเปิดให้ทุกคน สะกิดกันได้เลย</p>
                                            <p className="text text-center">หากท่านเคยทำการลงทะเบียนผ่านทาง SKITz และได้รับการยืนยันแล้ว สามารถเข้าสู่ระบบได้ <a href="https://store.skitz.com/">ที่นี่</a></p>
                                            <h3 className="mb-3 heading text-center">ลงทะเบียน</h3>
                                            <p className="text text-center">กรอกข้อมูลร้านของท่าน</p>
                                        </div>
                                        <form
                                            className="login-content mt-0"
                                            ref={(ref) => this.$form = window.$(ref)}
                                            // style={{ padding: 15, height: 705, overflowY: 'auto' }}
                                            onSubmit={async (e) => {
                                                e.preventDefault();
                                                if (this.submit.hasClass('loading')) { return false; }
                                                this.submit.addClass('loading');
                                                let data = Helper.getFormInputObject(this.$form);
                                                // console.log(data)
                                                let isPrivate = false;
                                                if (data.isPrivate && data.isPrivate === "true") {
                                                    isPrivate = true;
                                                }
                                                data.isPrivate = isPrivate;
                                                if (!data.lat || !data.lng ||
                                                    (data.lat && data.lat == "false")
                                                    ||
                                                    (data.lng && data.lng == "false")) {
                                                    Helper.messageTop({ message: `กรุณาระบบสถานที่บน Google Map` })
                                                    this.submit.removeClass('loading');
                                                    return;
                                                }
                                                if (!data.address && data.address === '') {
                                                    Helper.messageTop({ message: `กรุณากรอกที่อยู่` })
                                                    this.submit.removeClass('loading');
                                                    // window.$(document).Toasts('create', {
                                                    //     title: `กรุณาระบบรายละเอียดที่อยู่ร้าน`,
                                                    //     icon: 'fas fa-times',
                                                    //     autohide: true,
                                                    //     class: "bg-danger",
                                                    //     delay: 3000,
                                                    // })
                                                    return;
                                                }
                                                if (this.$file && this.$file[0].files[0]) {
                                                    await Helper.uploadFile(this.$file, 'store/', {
                                                        resize: true,
                                                        onSuccess: async (url) => {
                                                            console.log("url", url)
                                                            data.storagePath = url.storePath;
                                                            data.photoPath = url.imagePath;
                                                            if (this.props.mode === 'create') {
                                                                this.create(data);
                                                            }
                                                        },
                                                        onError: async (obj) => {
                                                            Helper.messageTop({ message: obj.message })
                                                            window.$(document).Toasts('create', {
                                                                title: obj.message,
                                                                icon: 'fas fa-times',
                                                                autohide: true,
                                                                class: "bg-danger",
                                                                delay: 3000,
                                                            })
                                                            this.submit.removeClass('loading');
                                                        }
                                                    })
                                                } else {

                                                    Helper.messageTop({ message: `กรุณาอัพโหลดรูปร้าน` });
                                                    this.submit.removeClass('loading');
                                                }

                                            }}
                                        >


                                            {/* <h5 className="mb-3 heading text-center">ข้อมูลเจ้าของร้าน</h5> */}

                                            <div className={"form-group"}>
                                                <label>ชื่อเจ้าของร้าน <small style={{ color: 'red' }}>*</small></label>
                                                <input type="text" className="form-control" name="providerName" required={true} />
                                            </div>
                                            <div className={"form-group"}>
                                                <label>เบอร์โทรศัพท์ <small style={{ color: 'red' }}>*</small></label>
                                                <input type="text" className="form-control" name="providerPhoneNumber" required={true} />
                                            </div>
                                            <div className={"form-group"}>
                                                <label>อีเมล <small style={{ color: 'red' }}>*</small></label>
                                                <input type="text" className="form-control" name="providerEmail" required={true} />
                                            </div>
                                            <div className={"form-group"}>
                                                <label>รหัสผ่าน <small style={{ color: 'red' }}>*</small></label>
                                                <input type="password" className="form-control" name="password" required={true} />
                                                <span style={{ fontSize: 13, color: '#ffffff', opacity: .5 }}>รหัสผ่านประกอบไปด้วย เลข,ตัวหนังสือพิมพ์เล็กและพิมพ์ใหญ่</span>
                                            </div>


                                            <h5 className="mb-3 heading text-center">รายละเอียดร้าน</h5>
                                            <div className={"form-group"}>
                                                <div className="flex row">
                                                    <label>รูปร้าน <small style={{ color: 'red' }}>*</small> </label>
                                                    <label className={"right-floated"}><small>ขนาดไม่เกิน 500x500 pixel</small></label>
                                                </div>
                                                <div >
                                                    <input name="image"
                                                        type={"file"}
                                                        accept="image/*"
                                                        ref={(ref) => this.$file = window.$(ref)}
                                                        style={{ display: 'none' }}
                                                        id="file_image_bank"
                                                        onChange={(e) => {
                                                            let res = Helper.readURL(this.$file);
                                                        }}
                                                    />
                                                    <div id="show_image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {
                                                            this.state.data && this.state.data.photoPath ?
                                                                <img src={this.state.data.photoPath} alt={"PromotionPic"} style={{ maxWidth: '100%', marginBottom: 5 }} />
                                                                : null
                                                        }
                                                    </div>
                                                    <label htmlFor="file_image_bank"
                                                        type={"button"}
                                                        className="btn main-btn"
                                                        style={{ width: '100%' }}
                                                    >
                                                        {
                                                            this.state.data && this.state.data.photoPath ?
                                                                "เปลี่ยนรูป"
                                                                : "อัพโหลด"
                                                        }
                                                    </label>
                                                </div>
                                            </div>

                                            {
                                                this.state.location && !this.state.map_loading ?
                                                    <div className="form-group">
                                                        <label>ที่อยู่ร้าน <small style={{ color: 'red' }}>*</small></label>
                                                        <GoogleMap
                                                            mode="view"
                                                            height={300}
                                                            lat={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false}
                                                            lng={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false}
                                                        />
                                                    </div>
                                                    : null
                                            }
                                            <div className={"form-group"}>
                                                <label>ที่อยู่ร้าน <small style={{ color: 'red' }}>*</small></label>
                                                <button
                                                    onClick={() => {
                                                        let geo = { lat: false, lng: false };
                                                        if (this.lat.val()) {
                                                            geo.lat = this.lat.val()
                                                        }
                                                        if (this.lat.val()) {
                                                            geo.lng = this.lng.val()
                                                        }
                                                        Helper.reactToDom(window.$('body'),
                                                            <GoogleMap
                                                                modalTitle={"ปักหมุดสถานที่"}
                                                                mode="modal"
                                                                height={300}
                                                                onSubmit={(location) => {
                                                                    this.setState({
                                                                        map_loading: true,
                                                                    }, () => {
                                                                        this.setState({
                                                                            location: location,
                                                                            map_loading: false,
                                                                        })
                                                                    })

                                                                }}
                                                                lat={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false}
                                                                lng={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false}
                                                            />)
                                                    }}
                                                    type={"button"}
                                                    className="btn main-btn"
                                                    style={{ width: '100%' }}
                                                >{this.state.location ? "เปลี่ยนตำแหน่ง" : "ระบุตำแหน่ง"}</button>
                                            </div>
                                            <div className={"form-group"}>
                                                <label>ชื่อร้าน (ไทย) <small style={{ color: 'red' }}>*</small></label>
                                                <input type="text" className="form-control" name="nameTH"
                                                    defaultValue={this.state.data && this.state.data.nameTH ? this.state.data.nameTH : null} />
                                            </div>
                                            <div className={"form-group"}>
                                                <label>ชื่อร้าน (อังกฤษ) <small style={{ color: 'red' }}>*</small></label>
                                                <input type="text" className="form-control" name="nameEN"
                                                    defaultValue={this.state.data && this.state.data.nameEN ? this.state.data.nameEN : null} />
                                            </div>

                                            <div className="form-group">
                                                <div className="flex row">
                                                    <label>ประเภท <small style={{ color: 'red' }}>*</small></label>
                                                </div>
                                                <select
                                                    // ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"storeType"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={this.state.data && this.state.data.storeTypeUid ? this.state.data.storeTypeUid : ''}
                                                >
                                                    <option value={""}>ไม่ระบุ</option>
                                                    {
                                                        this.state.store_types.map((store_type, store_type_i) => {
                                                            return (
                                                                <option value={store_type.uid} key={store_type_i}>
                                                                    {store_type.name}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <h5 className="mb-3 heading text-center">รายละเอียดที่อยู่</h5>
                                            <div className={"form-group"}>
                                                <label>ที่อยู่</label>
                                                <input type="text" className="form-control" name="address"
                                                    defaultValue={
                                                        this.state.data
                                                            && this.state.data.contact
                                                            && this.state.data.contact.address ?
                                                            this.state.data.contact.address
                                                            : null
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>จังหวัด</label>
                                                <select
                                                    // ref={(ref) => { window.$(ref).select2(); }}
                                                    className={"form-control select2"}
                                                    name={"provinceCode"}
                                                    style={{ width: '100%' }}
                                                    defaultValue={
                                                        this.state.data
                                                            && this.state.data.contact
                                                            && this.state.data.contact.provinceCode ?
                                                            this.state.data.contact.provinceCode
                                                            : null
                                                    }
                                                >
                                                    {
                                                        this.state.provinces.map((province, provinces_i) => {
                                                            return (
                                                                <option value={province.code} key={provinces_i}>
                                                                    {province.name.th}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <h5 className="mb-3 heading text-center">รายละเอียดการติดต่อ</h5>
                                            <div className={"form-group"}>
                                                <label>อีเมล</label>
                                                <input type="text" className="form-control" name="email"
                                                    defaultValue={
                                                        this.state.data
                                                            && this.state.data.contact
                                                            && this.state.data.contact.email ?
                                                            this.state.data.contact.email
                                                            : null
                                                    }
                                                />
                                            </div>
                                            <div className={"form-group"}>
                                                <label>เบอร์โทรศัพท์</label>
                                                <input type="text" className="form-control" name="telephone"
                                                    defaultValue={
                                                        this.state.data
                                                            && this.state.data.contact
                                                            && this.state.data.contact.telephone ?
                                                            this.state.data.contact.telephone.replace('+66', '0')
                                                            : null
                                                    }
                                                />
                                            </div>
                                            <div className={"form-group"}>
                                                <label>เว็บไซด์</label>
                                                <input type="text" className="form-control" name="website"
                                                    defaultValue={
                                                        this.state.data
                                                            && this.state.data.contact
                                                            && this.state.data.contact.website ?
                                                            this.state.data.contact.website
                                                            : null
                                                    }
                                                />
                                            </div>
                                            <h5 className="mb-3 heading text-center">เกี่ยวกับร้าน</h5>
                                            <div className="row form-group">
                                                <div className="col-6">
                                                    <label>ราคาเริ่มต้น</label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" name="minPrice"
                                                            defaultValue={this.state.data && this.state.data.minPrice ? this.state.data.minPrice : 0} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">฿</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>ราคาสิ้นสุด</label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" name="maxPrice"
                                                            defaultValue={this.state.data && this.state.data.maxPrice ? this.state.data.maxPrice : 0} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">฿</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>จำนวนผู้เข้าร้านสูงสุด</label>
                                                <div className="input-group">
                                                    <input type="number" className="form-control" name="guestLimit"
                                                        defaultValue={this.state.data && this.state.data.guestsLimit ? this.state.data.guestsLimit : 0} />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">คน</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>ภาพรวม <small style={{ color: 'red' }}>ไม่เกิน 500 ตัวอักษร</small></label>
                                                <textarea className="form-control" name="overview" maxLength={500}
                                                    defaultValue={this.state.data && this.state.data.overview ? this.state.data.overview : null}></textarea>
                                            </div>
                                            <label>วันทำการ</label>
                                            <div className="form-group">
                                                {/* MONDAY  */}
                                                <div>
                                                    <WorkingTimeInput
                                                        data={this.state.data
                                                            && this.state.data.workingTime
                                                            && this.state.data.workingTime.monday ?
                                                            this.state.data.workingTime.monday
                                                            : null
                                                        }
                                                        day={"monday"}
                                                        day_title={"วันจันทร์"}
                                                        time_step={this.state.time_step}
                                                    />
                                                    <div className="divider" />
                                                </div>
                                                {/* // TUESDAY */}
                                                <div>
                                                    <WorkingTimeInput
                                                        data={this.state.data
                                                            && this.state.data.workingTime
                                                            && this.state.data.workingTime.tuesday ?
                                                            this.state.data.workingTime.tuesday
                                                            : null
                                                        }
                                                        day={"tuesday"}
                                                        day_title={"วันอังคาร"}
                                                        time_step={this.state.time_step}
                                                    />
                                                    <div className="divider" />
                                                </div>
                                                {/* // WEDNESDAY */}
                                                <div>
                                                    <WorkingTimeInput
                                                        data={this.state.data
                                                            && this.state.data.workingTime
                                                            && this.state.data.workingTime.wednesday ?
                                                            this.state.data.workingTime.wednesday
                                                            : null
                                                        }
                                                        day={"wednesday"}
                                                        day_title={"วันพุธ"}
                                                        time_step={this.state.time_step}
                                                    />
                                                    <div className="divider" />
                                                </div>
                                                {/* // THURSDAY */}
                                                <div>
                                                    <WorkingTimeInput
                                                        data={this.state.data
                                                            && this.state.data.workingTime
                                                            && this.state.data.workingTime.thursday ?
                                                            this.state.data.workingTime.thursday
                                                            : null
                                                        }
                                                        day={"thursday"}
                                                        day_title={"วันพฤหัส"}
                                                        time_step={this.state.time_step}
                                                    />
                                                    <div className="divider" />
                                                </div>
                                                {/* // FRIDAY */}
                                                <div>
                                                    <WorkingTimeInput
                                                        data={this.state.data
                                                            && this.state.data.workingTime
                                                            && this.state.data.workingTime.friday ?
                                                            this.state.data.workingTime.friday
                                                            : null
                                                        }
                                                        day={"friday"}
                                                        day_title={"วันศุกร์"}
                                                        time_step={this.state.time_step}
                                                    />
                                                    <div className="divider" />
                                                </div>
                                                {/* // SATURDAY */}
                                                <div>
                                                    <WorkingTimeInput
                                                        data={this.state.data
                                                            && this.state.data.workingTime
                                                            && this.state.data.workingTime.saturday ?
                                                            this.state.data.workingTime.saturday
                                                            : null
                                                        }
                                                        day={"saturday"}
                                                        day_title={"วันเสาร์"}
                                                        time_step={this.state.time_step}
                                                    />
                                                    <div className="divider" />
                                                </div>
                                                {/* // SUNDAY */}
                                                <div>
                                                    <WorkingTimeInput
                                                        data={this.state.data
                                                            && this.state.data.workingTime
                                                            && this.state.data.workingTime.sunday ?
                                                            this.state.data.workingTime.sunday
                                                            : null
                                                        }
                                                        day={"sunday"}
                                                        day_title={"วันอาทิตย์"}
                                                        time_step={this.state.time_step}
                                                    />
                                                </div>
                                            </div>


                                            <input type="hidden" ref={(ref) => { this.lat = window.$(ref) }} name="lat" value={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false} />
                                            <input type="hidden" ref={(ref) => { this.lng = window.$(ref) }} name="lng" value={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false} />
                                            <div className="flex row">
                                                <div className={" mt-5"} style={{ margin: 'auto' }}>
                                                    <input name="freeDate" type="hidden" value={"0"} />
                                                    {
                                                        this.props.mode === 'edit' ?
                                                            <>
                                                                <input name="uid" type="hidden" value={this.props.uid} />
                                                                <input name="packageUid" type="hidden" value={this.state.data.packageUid} />
                                                                <input name="packageOptionUid" type="hidden" value={this.state.data.packageOptionUid} />
                                                                <input name="status" type="hidden" value={this.state.data.status || 'pause'} />
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        this.props.mode === 'create' ?
                                                            <input name="status" type="hidden" value={"pause"} />
                                                            : null
                                                    }
                                                    <input type="hidden" name="packageUid" value="4yvDPstPnEOCzI3bQd9X" />
                                                    <input name="type" type="hidden" value={this.props.type} />
                                                    <button type="submit"
                                                        className="btn btn-lg rounded-pill main-btn inverted"
                                                        ref={(ref) => { this.submit = window.$(ref) }}
                                                    >
                                                        {this.props.mode === 'create' ? 'ลงทะเบียน' : null}
                                                        {this.props.mode === 'edit' ? 'บันทึก' : null}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                    </>
                                    : null
                            }
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}


class WorkingTimeInput extends React.Component {
    workingTimeSelect(ref) {
        let _ref = window.$(ref.target);
        if (_ref.prop("checked") === true) {
            _ref.parents('.sub-days').find('.day-times').show();
        }
        else if (_ref.prop("checked") === false) {
            _ref.parents('.sub-days').find('.day-times').hide();
        }
    }
    setTimepicker(ref) {
        window.$(ref).datetimepicker({
            format: 'HH:mm',
            stepping: this.props.time_step,
        })
    }
    render() {
        return (
            <div className="day-group">
                {/* <label htmlFor="sundayOpen">วันอาทิตย์ </label> */}
                <div className="sub-days">
                    <label className="custom-control custom-switch">
                        <input type="checkbox"
                            className="custom-control-input"
                            id={this.props.day + 'Open'}
                            name={this.props.day + "Open"}
                            defaultValue={"true"}
                            defaultChecked={
                                this.props.data
                                    && this.props.data.isOpen ?
                                    this.props.data.isOpen : false
                            }
                            onChange={(ref) => {
                                this.workingTimeSelect(ref)
                            }}
                        />
                        <label className="custom-control-label" htmlFor={this.props.day + 'Open'}>{this.props.day_title}</label>
                    </label>
                    <div className="day-times" style={{
                        display: this.props.data
                            && this.props.data.isOpen ? "block" : "none"
                    }}>
                        <div className="row">
                            <div className="col-5">
                                <div className="input-group date" id={this.props.day + '_timepicker_open'} data-target-input="nearest"
                                    ref={(ref) => {
                                        this.setTimepicker(ref);
                                    }}
                                >
                                    <input type="text"
                                        name={this.props.day + "TimeOpen"}
                                        className="form-control datetimepicker-input"
                                        data-target={"#" + this.props.day + '_timepicker_open'}
                                        data-toggle="datetimepicker"
                                        defaultValue={
                                            this.props.data
                                                && this.props.data.time
                                                && this.props.data.time.open ?
                                                this.props.data.time.open : '00:00'
                                        }
                                    />
                                    <div className="input-group-append" data-target={"#" + this.props.day + '_timepicker_open'} data-toggle="datetimepicker">
                                        <div className="input-group-text"><i className="fas fa-door-open"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <i className="fas fa-minus"></i>
                            </div>
                            <div className="col-5">
                                <div className="input-group date" id={this.props.day + '_timepicker_close'} data-target-input="nearest"
                                    ref={(ref) => {
                                        this.setTimepicker(ref);
                                    }}
                                >
                                    <input type="text"
                                        name={this.props.day + "TimeClose"}
                                        className="form-control datetimepicker-input"
                                        data-target={"#" + this.props.day + '_timepicker_close'}
                                        data-toggle="datetimepicker"
                                        defaultValue={
                                            this.props.data
                                                && this.props.data.time
                                                && this.props.data.time.close ?
                                                this.props.data.time.close : '00:00'
                                        }
                                    />
                                    <div className="input-group-append" data-target={"#" + this.props.day + '_timepicker_close'} data-toggle="datetimepicker">
                                        <div className="input-group-text"><i className="fas fa-door-closed"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}