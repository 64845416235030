import React from "react";
import Header from "../Include/Header";
import Footer from "../Include/Footer";
export default class DownloadMedia extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <div className="card-box">
                        {/* <h2 style={{ textAlign: 'center' }}>Download Media</h2> */}
                        <div className="row">


                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <div className="icon">
                                        <img src="./assets/images/illustrator.png" />
                                    </div>
                                    <img src="./assets/images/sticker/Newskitz-qr.png" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn login-btn btn-block" href="./assets/images/sticker/Newskitz-qr.ai" download={true}>Download</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <img src="./assets/images/sticker/Newskitz-qr.png" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn login-btn btn-block" href="./assets/images/sticker/Newskitz-qr.png" download={true}>Download</a>
                                </div>
                            </div>

                            
                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <div className="icon">
                                        <img src="./assets/images/illustrator.png" />
                                    </div>
                                    <img src="./assets/images/sticker/skitz-logo.png" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn login-btn btn-block" href="./assets/images/sticker/skitz-logo.ai" download={true}>Download</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <img src="./assets/images/sticker/skitz-logo.png" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn login-btn btn-block" href="./assets/images/sticker/skitz-logo.png" download={true}>Download</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <img src="./assets/images/sticker/skitz-logo1.png" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn login-btn btn-block" href="./assets/images/sticker/skitz-logo1.png" download={true}>Download</a>
                                </div>
                            </div>

                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <div className="icon">
                                        <img src="./assets/images/illustrator.png" />
                                    </div>
                                    <img src="./assets/images/sticker/sticker-Newskitz-qrdownload-Partner.jpg" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn login-btn btn-block" href="./assets/images/sticker/sticker-Newskitz-qrdownload-Partner.ai" download={true}>Download</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <img src="./assets/images/sticker/sticker-Newskitz-qrdownload-Partner.jpg" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn login-btn btn-block" href="./assets/images/sticker/sticker-Newskitz-qrdownload-Partner.jpg" download={true}>Download</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <img src="./assets/images/sticker/sticker-Newskitz-qrdownload-Partner2.jpg" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn login-btn btn-block" href="./assets/images/sticker/sticker-Newskitz-qrdownload-Partner.ai" download={true}>Download</a>
                                </div>
                            </div>

                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <div className="icon">
                                        <img src="./assets/images/illustrator.png" />
                                    </div>
                                    <img src="./assets/images/sticker/sticker-Newskitz-qr-new.jpg" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn login-btn btn-block" href="./assets/images/sticker/sticker-Newskitz-qr-new.ai" download={true}>Download</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <img src="./assets/images/sticker/sticker-Newskitz-qr-new.jpg" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn login-btn btn-block" href="./assets/images/sticker/sticker-Newskitz-qr-new.jpg" download={true}>Download</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 mb-3">
                                <div className="download-media-item">
                                    <img src="./assets/images/gallery.png" />
                                </div>
                                <div className="mt-3">
                                    <a className="btn main-btn  btn-block" style={{ borderRadius: '30px'}} href="./assets/images/sticker.zip" download={true}>Download All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}