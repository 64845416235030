import httpClient, { skitz_api_host } from './HttpClient'
const _serverUrl = skitz_api_host;

const baseUrl = `${_serverUrl}/user`;
export const getUser = async (uid) => {
    const url = `${baseUrl}/${uid}`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
