import React from 'react';
// import { Link } from "@reach/router"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
// import { firestore } from '../firebase';
import { Helper } from '../Helper';
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: {
                lat: props.lat || 13.631787064094638,
                lng: props.lng || 100.54314216378978,
            },
            // api_key: "AIzaSyCiz0jabZax2-qrOy2nQo0_IKOKlf_6Op8"
        }
    }

    componentDidMount() {
        // this.fetch()
    }

    render() {
        const MyMapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={this.props.mode !== 'view' ? 16 : 15}
                defaultCenter={{ lat: this.state.location.lat, lng: this.state.location.lng }}
            >
                {props.isMarkerShown &&
                    <Marker position={{ lat: this.state.location.lat, lng: this.state.location.lng }}
                        draggable={this.props.mode !== 'view'}
                        onDragEnd={(e) => {
                            let location = { ...this.state.location };
                            location.lat = e.latLng.lat();
                            location.lng = e.latLng.lng();
                            // let coordinate = firestore(location.lat, location.lng);
                            // placesRef.update({ coordinate: coordinate });
                            if (this.props.mode === 'modal') {
                                this.lat.val(location.lat);
                                this.lng.val(location.lng)
                            }
                            if (this.props.onSelected && this.props.mode !== 'modal') {
                                this.props.onSelected(location);
                            }
                        }}
                    />}
            </GoogleMap>
        ))
        if (this.props.mode === 'modal') {
            return (
                <React.Fragment>
                    <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                        style={{ maxWidth: '100%' }}
                        ref={(ref) => {
                            this.$modal = window.$(ref);
                            this.$modal.modal('show');
                            this.$modal.on('hidden.bs.modal', () => {
                                let settime = window.setTimeout(() => {
                                    this.$modal.remove();
                                    clearTimeout(settime);
                                }, 500)
                            });
                        }}
                    >
                        <form className="modal-dialog modal-lg" role="document"
                            style={{ maxWidth: 1200 }}
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.$form = this.$modal.find('form');
                                let $submit = this.$form.find('button[type="submit"]');
                                if ($submit.hasClass('loading')) { return false; }
                                $submit.addClass('loading');
                                let data = Helper.getFormInputObject(this.$form);
                                // console.log(data);
                                if (this.props.onSubmit) {
                                    this.props.onSubmit(data);
                                }
                                this.$modal.modal('hide')
                                // return;
                                // this.update(data);

                            }}
                        >
                            <div className="modal-content" >
                                <div className="modal-header">
                                    <h4 className="modal-title" id="myModalLabel">{this.props.modalTitle || "GoogleMapForm"}</h4>

                                </div>

                                <div className="modal-body">
                                    <div className={"mt-3 mb-3"}>
                                        <GooglePlacesAutocomplete
                                            selectProps={{
                                                placeholder: 'ค้นหาจากชื่อสถานที่',
                                                onChange: async (e) => {
                                                    // console.log(e);
                                                    let geo = await geocodeByPlaceId(e.value.place_id);
                                                    let lat = geo[0].geometry.location.lat();
                                                    let lng = geo[0].geometry.location.lng();
                                                    let location = { ...this.state.location };
                                                    location.lat = lat;
                                                    location.lng = lng;
                                                    this.setState({
                                                        location: location
                                                    })
                                                },
                                                noOptionsMessage: () => {
                                                    return 'ไม่พบข้อมูล'
                                                },
                                                loadingMessage: () => {
                                                    return 'กำลังค้นหาสถานที่'
                                                }
                                            }}
                                        // apiKey="AIzaSyBVznn93l6d08G6UunFanX2Mjo73dwbEPk"
                                        />
                                    </div>
                                    <MyMapComponent
                                        isMarkerShown
                                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyCiz0jabZax2-qrOy2nQo0_IKOKlf_6Op8&v=3.exp&libraries=geometry,drawing,places"}
                                        // googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: this.props.height + 'px' || `800px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                    />
                                    <input type="hidden" ref={(ref) => { this.lat = window.$(ref) }} name="lat" value={this.state.location.lat} />
                                    <input type="hidden" ref={(ref) => { this.lng = window.$(ref) }} name="lng" value={this.state.location.lng} />
                                </div>
                                <div className="modal-footer">
                                    <button type="button"
                                        className="btn btn-default"
                                        onClick={() => {
                                            this.$modal.modal('hide');
                                        }}
                                    >ยกเลิก</button>
                                    <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div style={{ border: '4px solid #fff', position: 'relative' }}>
                    {
                        this.props.mode !== 'view' ?
                            <>
                                <button
                                    style={{ position: 'absolute', bottom: 4, left: 4, zIndex: 1 }}
                                    className="btn btn-primary"
                                    type={"button"}
                                    onClick={() => {
                                        Helper.reactToDom(window.$('body'),
                                            <SearchBox onSelected={(location) => {
                                                this.setState({
                                                    location: location
                                                }, () => {
                                                    if (this.props.onSelected
                                                        && this.props.mode !== 'modal') {
                                                        this.props.onSelected(location);
                                                    }
                                                })
                                            }} />
                                        )
                                    }}
                                >ค้นหาสถานที่ </button>
                            </>
                            : null
                    }

                    <MyMapComponent
                        isMarkerShown
                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyBVznn93l6d08G6UunFanX2Mjo73dwbEPk&v=3.exp&libraries=geometry,drawing,places"}
                        // googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: this.props.height + 'px' || `800px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                    {
                        !this.props.onSelected ?
                            <>
                                <input type="hidden" ref={(ref) => { this.lat = window.$(ref) }} name="lat" value={this.state.location.lat} />
                                <input type="hidden" ref={(ref) => { this.lng = window.$(ref) }} name="lng" value={this.state.location.lng} />
                            </>
                            : null
                    }
                </div>
            </React.Fragment>
        )
    }
}

class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: {
                lat: props.lat || 13.631787064094638,
                lng: props.lng || 100.54314216378978,
            },
            // api_key: "AIzaSyCiz0jabZax2-qrOy2nQo0_IKOKlf_6Op8"
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    style={{ maxWidth: '100%' }}
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog modal-lg" role="document"
                        style={{ maxWidth: 1200 }}
                    >
                        <div className="modal-content" style={{ width: 1200 }}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">ค้นหาสถานที่</h4>
                            </div>
                            <div className="modal-body">
                                <GooglePlacesAutocomplete
                                    selectProps={{
                                        placeholder: 'ค้นหาจากชื่อสถานที่',
                                        onChange: async (e) => {
                                            // console.log(e);
                                            let geo = await geocodeByPlaceId(e.value.place_id);
                                            let lat = geo[0].geometry.location.lat();
                                            let lng = geo[0].geometry.location.lng();
                                            let location = { ...this.state.location };
                                            location.lat = lat;
                                            location.lng = lng;
                                            this.setState({
                                                location: location
                                            }, () => {
                                                if (this.props.onSelected) {
                                                    this.props.onSelected(this.state.location);
                                                    this.$modal.modal('hide');
                                                }
                                            })
                                        },
                                        noOptionsMessage: () => {
                                            return 'ไม่พบข้อมูล'
                                        },
                                        loadingMessage: () => {
                                            return 'กำลังค้นหาสถานที่'
                                        }
                                    }}
                                // apiKey="AIzaSyBVznn93l6d08G6UunFanX2Mjo73dwbEPk"
                                />
                            </div>
                            <div className="modal-footer" style={{ display: 'none' }}>
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}